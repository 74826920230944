<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <h2 class="title">
    The Two Sample MR results for outcome: {{ study }}
  </h2>
  <div class="table_search">
    <div class="table_select">
      <h2 >Select a exposure:</h2>
      <el-select-v2
        v-model="exposure"
        placeholder="input and select a exposure"
        filterable
        :options="exposureOptions"
        style="width: 240px;margin-left: 10px"
      />
    </div>
  </div>
  <MR :study="study" :exposure="exposure"></MR>
  <el-backtop :right="50" :bottom="50" />
</template>

<script>
import { reactive, ref, watch, onMounted, computed, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
import { ElSelectV2, ElBacktop, ElSelect, ElOption, ElDivider, ElButton } from 'element-plus';
import MR from '../components/MR.vue'


export default {
  name:'TSMR',
  components:{
    ElSelectV2,
    MR,
    ElBacktop,
    ElSelect, 
    ElOption,
    ElDivider,
    ElButton
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const study = ref(route.params.study)
    const selectedStudy = ref(route.params.study)
    var exposure = ref('')
    var exposureOptions = ref([])
    var studyOptions = ref([])

    const fetchData = async () => {
      try {
        const response = await proxy.$request.get('/getExposure',{ 
          params:{
            outcome_id:study.value
        }})
        exposureOptions.value = response.data.map((item) => {
          return {
            value:item.exposure_id,
            label:item.exposure_MeSH_term
          }
        })
        exposure.value = response.data[0].exposure_id
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: 'TSMR'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    watch(study, (newValue, oldValue) => {
      fetchData()
    })

    onMounted(() => {
      fetchStudy()
      fetchData()
    })

    return {
      study,
      selectedStudy,
      studyOptions,
      exposure,
      exposureOptions,
      searchStudy,
      fetchStudy
    }
  }
}
</script>

<style>

</style>