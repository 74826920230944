<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <div class="heritability">
    <h2 style="text-align: center;font-size: 32px">The SNP-heritability computed by LDSC and LDAK for {{ study }}</h2>
    <div ref="h2Chart" class="bar"></div>
    <div>
      <div  class="table_container">
        <el-table 
          :data="h2TableData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
        >
          <el-table-column prop="study" label="Study" min-width="150" />
          <el-table-column prop="trait" label="Trait" min-width="150">
            <template v-slot:default>
              <div>
                {{ trait }}
              </div>
            </template>
          </el-table-column>            
          <el-table-column prop="ldakH2" label="LDAK h2" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldakH2Sd" label="LDAK h2_sd" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldakP" label="LDAK h2_p" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldakInflu" label="LDAK influence" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldakInfluSd" label="LDAK influence_sd" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldscH2" label="LDSC h2" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldscH2Se" label="LDSC h2_se" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldscH2P" label="LDSC h2_p" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldscZ" label="LDSC h2_zsocre" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldscGc" label="LDSC gc" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ldscMeanChi2" label="LDSC mean chi2" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="intercept" label="LDSC intercept" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="interceptSe" label="LDSC intercept_se" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ratio" label="LDSC ratio" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column prop="ratioSe" label="LDSC ratio_se" min-width="150" :formatter="$utils.NAFormat" />
        </el-table>
      </div>
    </div>
  </div>
  <el-divider />
  <div class="heritability_enrichment">
    <h2 style="text-align: center;font-size: 32px">The heritability enrichment computed by LDAK for {{ study }}</h2>
    <div ref="h2EnrichChart" class="bar"></div>
    <div>
      <div class="table_container">
        <el-table 
          :data="HEpagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortLDAKChange"
        >
          <el-table-column prop="study" label="Study" min-width="150" />
          <el-table-column prop="trait" label="Trait" min-width="150">
            <template v-slot:default>
              <div>
                {{ trait }}
              </div>
            </template>
          </el-table-column> 
          <el-table-column sortable prop="component" label="Component" min-width="150"  />
          <el-table-column sortable prop="share" label="Share" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="shareSd" label="LDSC h2_zsocre" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="expected" label="Expected" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="enrichment" label="Enrichment" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="enrichmentSd" label="Enrichment SD" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="enrichmentP" label="Enrichment P" min-width="150" :formatter="$utils.NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleHESizeChange"
          @current-change="handleHECurrentChange"
          :current-page="currentHEPage"
          :total="h2EnrichTotal"
          :page-sizes="[5,10,20]"
          :page-size="HEpageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        />
      </div>
    </div>
  </div>
  <el-divider />
  <div class="genetic_correlation">
    <h2 style="text-align: center;font-size: 32px">The genetic correlation between traits</h2>
    <div class="geneCor">
      <div ref="geneCorChart1" class="bar"></div>
      <div ref="geneCorChart2" class="bar"></div>
    </div>
    <div class="table_search">
      <div class="table_select">
        <div class="search_title">Trait 2:</div>
        <div>
          <el-select
            v-model="trait2"
            placeholder="input and select a trait 2"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in study2Options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="table_select">
        <div class="search_title">LDAK RG  ></div>
        <el-input v-model="LDAK_RG" style="width: 240px" type="number" placeholder="input LDAK RG" />
      </div>
      <div class="table_select">
        <div class="search_title">LDAK P  {{ '<' }}</div>
        <el-input v-model="LDAK_P" style="width: 240px" type="number" placeholder="input LDAK P" />
      </div>
      <div class="table_select">
        <div class="search_title">LDSC RG  ></div>
        <el-input v-model="LDSC_RG" style="width: 240px" type="number" placeholder="input LDSC RG" />
      </div>
      <div class="table_select">
        <div class="search_title">LDSC P  {{ '<' }}</div>
        <el-input v-model="LDSC_P" style="width: 240px" type="number" placeholder="input LDSC P" />
      </div>
      <div class="search_button">
          <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
          <el-button @click="reset">Reset</el-button>
        </div>
    </div>
    <div>
      <div  class="table_container">
        <el-table 
          :data="h2GencorTableData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortGCChange"
        >
          <el-table-column prop="study1" label="Study 1" min-width="150" />
          <el-table-column prop="trait1" label="Trait 1" min-width="150">
            <template v-slot:default>
              <div>
                {{ trait }}
              </div>
            </template>
          </el-table-column> 
          <el-table-column sortable prop="study2" label="Study 2" min-width="150" />
          <el-table-column sortable prop="trait2" label="Trait 2" min-width="150" />
          <el-table-column sortable prop="ldak_gc" label="LDAK RG" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="ldak_gc_sd" label="LDAK RG SD" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="ldak_gc_p" label="LDAK RG P" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="ldsc_gc" label="LDSC RG" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="ldsc_gc_se" label="LDSC RG SE" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_gc_z" label="LDSC RG Z" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_gc_p" label="LDSC RG P" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldak_1_her" label="LDAK Study1 HER" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldak_1_her_sd" label="LDAK study1 HER SD" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldak_2_her" label="LDAK Study2 HER" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldak_2_her_sd" label="LDAK Study2 HER SD" min-width="150" :formatter="$utils.NAFormat"  /> 
          <el-table-column sortable prop="ldsc_2_h2" label="LDSC Study2 h2" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_2_h2_se" label="LDSC Study2 h2 SE" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_2_h2_int" label="LDSC Study2 h2 intercept" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_2_h2_int_se" label="LDSC Study2 h2 intercept SE" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_gencov" label="LDSC gencov" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_gencov_se" label="LDSC gencov SE" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_gencov_int" label="LDSC gencov intercept" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ldsc_gencov_int_se" label="LDSC gencov intercept SE" min-width="150" :formatter="$utils.NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleHGCSizeChange"
          @current-change="handleHGCCurrentChange"
          :current-page="currentHGCPage"
          :total="h2GCTotal"
          :page-sizes="[5]"
          :page-size="HGCPageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        />
      </div>
    </div>
  </div>
  <el-backtop :right="50" :bottom="50" />
</template>

<script>
import { ElDivider, ElSelect, ElOption, ElTable, ElTableColumn, ElButton, ElInput, ElPagination, ElBacktop } from 'element-plus';
import * as echarts from 'echarts'
import { reactive, ref, onMounted, computed, watch, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name:'heritability',
  components:{
    ElDivider,
    ElSelect, 
    ElOption, 
    ElTable, 
    ElTableColumn, 
    ElButton,
    ElInput,
    ElPagination,
    ElBacktop
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const study = ref(route.params.study)
    const selectedStudy = ref(route.params.study)
    const trait = ref('')
    const h2Chart = ref(null);
    const h2EnrichChart = ref(null)
    const geneCorChart1 = ref(null)
    const geneCorChart2 = ref(null)
    var h2TableData = ref([])
    var h2EnrichTableData = ref([])
    var h2GencorTableData = ref([])
    var genCor1Data = ref([])
    var genCor2Data = ref([])
    var genCor1Link = ref([])
    var genCor2Link = ref([])
    var study2Options = ref([])
    var studyOptions = ref([])
    var trait2 = ref('')
    var LDAK_RG = ref(null)
    var LDAK_P = ref(null)
    var LDSC_RG = ref(null)
    var LDSC_P = ref(null)

    var h2ChartInit = () => {
      let Chart = echarts.getInstanceByDom(h2Chart.value);
      if(Chart == null){
        Chart = echarts.init(h2Chart.value);
      }
      // 绘制图表
      let options = {
        tooltip: {
          formatter: function (params) {
            if(params.seriesType == 'custom'){
              return (
                params.seriesName+' error bar: '+params.value[2].toFixed(4)+'\t'+params.value[1].toFixed(4)
              )
            }
            else{
              return (
                params.seriesName+': '+params.value.toFixed(4)
              );
            }
          }
        },
        legend:{
          selectedMode:false,
          data:[
            {
              name:'LDAK'
            },
            {
              name:'LDSC'
            }
          ]
        },
        xAxis: {
          type:'category',
          data: ["AT011"],
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        series: [
          {
            name: "LDAK",
            itemStyle:{
              color:'#5f97d2'
            },
            type: "bar",
            data: [Number(h2TableData.value[0].ldakH2)]
          },
          {
            name: "LDSC",
            itemStyle:{
              color:'#d68a56'
            },
            type: "bar",
            data: [Number(h2TableData.value[0].ldscH2)]
          },
          {
            type:'custom',
            name:'LDSC',
            itemStyle: {
              borderWidth: 1.5,
              color:'#b55c21'
            },
            renderItem: function(params, api){
              var xValue = api.value(0);
              var highPoint = api.coord([xValue, api.value(1)]);
              var lowPoint = api.coord([xValue, api.value(2)]);
              var halfWidth = api.size([1,0])[0] * 0.1;
              var style = api.style({
                stroke:api.visual('color'),
                fill:undefined
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1:highPoint[0] + 1.3*halfWidth,
                      y1:highPoint[1],
                      x2:highPoint[0] + 2.7*halfWidth,
                      y2:highPoint[1]
                    },
                    style:style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] + 2*halfWidth,
                      y1: highPoint[1],
                      x2: lowPoint[0] + 2*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] + 1.3*halfWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] + 2.7*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  }
                ]
              };
            },
            encode: {
              x: 0,
              y: [1,2]
            },
            data: [[0, Number(h2TableData.value[0].ldscH2)+Number(h2TableData.value[0].ldscH2Se), Number(h2TableData.value[0].ldscH2)-Number(h2TableData.value[0].ldscH2Se)]],
            z:100
          },
          {
            type:'custom',
            name:'LDAK',
            itemStyle: {
              borderWidth: 1.5,
              color:'#176299'
            },
            renderItem: function(params, api){
              var xValue = api.value(0);
              var highPoint = api.coord([xValue, api.value(1)]);
              var lowPoint = api.coord([xValue, api.value(2)]);
              var halfWidth = api.size([1,0])[0] * 0.1;
              var style = api.style({
                stroke:api.visual('color'),
                fill:undefined
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1:highPoint[0] - 2.7*halfWidth,
                      y1:highPoint[1],
                      x2:highPoint[0] - 1.3*halfWidth,
                      y2:highPoint[1]
                    },
                    style:style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] - 2*halfWidth,
                      y1: highPoint[1],
                      x2: lowPoint[0] - 2*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] - 2.7*halfWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] - 1.3*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  }
                ]
              };
            },
            encode: {
              x: 2,
              y: 1
            },
            data: [[0, Number(h2TableData.value[0].ldakH2)+Number(h2TableData.value[0].ldakH2Sd), Number(h2TableData.value[0].ldakH2)-Number(h2TableData.value[0].ldakH2Sd)]],
            z:100
          }
        ],
      }; 
      // 渲染图表
      Chart.setOption(options);
    }

    var region = ref([]);
    // prettier-ignore
    var traitList = ref([]);
    // prettier-ignore
    const HEData = ref([]);

    var h2EnrichChartInit = () => {
      let Chart = echarts.getInstanceByDom(h2EnrichChart.value);
      if(Chart == null){
        Chart = echarts.init(h2EnrichChart.value);
      }
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Enrichment: ' +
              params.value[2] +
              '<br>Enrichment P: ' +
              params.value[3]
            );
          }
        },
        grid: {
          bottom:'30%'
        },
        xAxis: {
          type: 'category',
          data: region.value,
          name:'Region',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -40
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: traitList.value,
          name:'Trait',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'Enrichment',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return val[2] > 0 ? Math.pow(val[2], 1/3)*5 : 0;
            },
            data: HEData.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0,
          max: 1,
          dimension: 3,
          right: 0,
          precision:2,
          top: 'center',
          text: ['', 'P'],
          calculable: true,
          inRange: {
            color: ['#ff0004', '#0055ff']
          }
        },
      };
      Chart.setOption(options);
    }

    var geneCorChart1Init = () => {
      let Chart = echarts.getInstanceByDom(geneCorChart1.value)
      if(Chart == null){
        Chart = echarts.init(geneCorChart1.value);
      }
      let options = {
        animation:false,
        title:{
          text:'LDAK',
          left:'center',
          textStyle: {
            color:'black'
          }
        },
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'edge') {
              return 'RG: '+params.value[0]+
                     '<br>RG P: '+params.value[1]
            }else{
              return 'Trait: '+params.data.value
            }
          }
        },
        grid: {
        },
        series: [
          {
            name: 'genetic correlation',
            type: 'graph',
            layout: 'force',
            force: {
              center: study.value,
              repulsion: 5000,
              edgeLength: 50,
              initLayout: 'circular',
              layoutAnimation: true
            },
            label: {
              normal: {
                show: true,
                position: 'inside',
                textStyle: {
                  fontSize: 16,
                  color: 'black'
                }
              }
            },
            data: genCor1Data.value,
            links: genCor1Link.value
          }
        ],
      };
      Chart.setOption(options);
    }
    
    var geneCorChart2Init = () => {
      let Chart = echarts.getInstanceByDom(geneCorChart2.value)
      if(Chart == null){
        Chart = echarts.init(geneCorChart2.value);
      }
      let options = {
        title:{
          text:'LDSC',
          left:'center',
          textStyle: {
            color:'black'
          }
        },
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'edge') {
              return 'RG: '+params.value[0]+
                     '<br>RG P: '+params.value[1]
            }else{
              return 'Trait: '+params.data.value
            }
          }
        },
        grid: {
        },
        series: [
          {
            name: 'genetic correlation',
            type: 'graph',
            layout: 'force',
            force: {
              center: study.value,
              repulsion: 5000,
              edgeLength: 50,
              initLayout: 'circular',
              layoutAnimation: true
            },
            label: {
              normal: {
                show: true,
                position: 'inside',
                textStyle: {
                  fontSize: 16,
                  color: 'black'
                }
              }
            },
            data: genCor2Data.value,
            links: genCor2Link.value
          }
        ],
      };
      Chart.setOption(options);
    }

    var currentHEPage = ref(1)
    var HEpageSize = ref(5)
    var h2EnrichTotal = ref(0)

    const HEpagedData = computed(() => {
      const startIndex = (currentHEPage.value - 1) * HEpageSize.value;
      const endIndex = startIndex + HEpageSize.value;
      return h2EnrichTableData.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleHECurrentChange = (newPage) => {
      currentHEPage.value = newPage;
    };
    // 处理每页条数改变事件
    const handleHESizeChange = (newSize) => {
      HEpageSize.value = newSize;
    };

    const sortLDAKChange = (arg) => {
      let sortData = h2EnrichTableData.value
      if (arg.order === 'descending') {
        if (arg.prop === 'COMPONENT'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'COMPONENT'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      h2EnrichTableData.value = sortData
      currentHEPage.value = 1
    }

    var prop = ''
    var order = ''
    const sortGCChange = (arg) => {
      prop = arg.prop
      order = arg.order
      fetchH2GenCorData()
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    var currentHGCPage = ref(1)
    var HGCPageSize = ref(5)
    var h2GCTotal = ref(0)

    // 处理页码改变事件
    const handleHGCCurrentChange = (newPage) => {
      currentHGCPage.value = newPage;
      fetchH2GenCorData()
    };

    // 处理每页条数改变事件
    const handleHGCSizeChange = (newSize) => {
      HGCPageSize.value = newSize;
      fetchH2GenCorData()
    };

    const fetchTrait = async () => {
      try {
        const response = await proxy.$request.get('/getTrait',{
          params:{
            study: study.value
          }
        })
        trait.value = response.data
      } catch(error) {
        console.log('fail', error.message)
      }
    }

    const fetchH2Data = async () => {
      try {
        const response = await proxy.$request.get('/getHeritability',{ 
          params:{
            study: study.value
        }})
        h2TableData.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchH2EnrichData = async () => {
      try {
        const response = await proxy.$request.get('/getHeritabilityEnrichment',{ 
          params:{
            study:study.value
        }})
        h2EnrichTableData.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchH2GenCorData = async () => {
      try {
        const response = await proxy.$request.get('/getHeritabilityGenCor',{ 
          params:{
            study: study.value,
            trait: trait2.value,
            ldakRg: LDAK_RG.value,
            ldakP: LDAK_P.value,
            ldscRg: LDSC_RG.value,
            ldscP: LDSC_P.value,
            page: currentHGCPage.value,
            pageSize: HGCPageSize.value,
            prop: prop,
            order: order
        }})
        h2GencorTableData.value = response.data.rows
        h2GCTotal.value = response.data.total
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchTrait2 = async () => {
      try{
        const response = await proxy.$request.get('/getHeritabilityTrait',{
          params:{
            study: study.value
          }
        })
        study2Options.value = response.data.map(item => ({
          label:item,
          value:item
        }))
      } catch (error){
        console.log('fail', error.message)
      }
    }

    var filterByTraitOrAuthor = () => {
      fetchH2GenCorData()
    }

    var reset = () => {
      trait2.value = ''
      LDAK_RG.value = null
      LDAK_P.value = null
      LDSC_RG.value = null
      LDSC_P.value = null
      currentHGCPage.value = 1
      fetchH2GenCorData()
    }

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: 'LDSC'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    watch(h2TableData, (newValue, oldValue) => {
      h2ChartInit();
    })

    watch(h2EnrichTableData, (newValue, oldValue) => {
      h2EnrichTotal.value = h2EnrichTableData.value.length
      traitList.value = [study.value]
      region.value = []
      HEData.value = h2EnrichTableData.value.map((item,index) => {
        region.value.push(item.component)
        return (
          [index, 0, item.enrichment, item.enrichmentP, -1.0*(Math.log10(item.enrichmentP))]
        )
      })
      h2EnrichChartInit();
    })

    watch(h2GencorTableData, (newValue, oldValue) => {
      var trait1 = ''
      var tmp = h2GencorTableData.value.filter(
        (data) => {
          return data.ldak_gc != null
        }
      )
      genCor1Data.value = tmp.map((item) => {
        return {
          name:item.study2,
          symbolSize: [80, 80],
          value:item.trait2
        }
      })
      if (h2GencorTableData.value.length != 0){
        trait1 = trait.value
      }
      genCor1Data.value.push({
        name:study.value,
        x: 200,
        y: 200,
        symbolSize: [80, 80],
        value: trait1
      })
      genCor1Link.value = h2GencorTableData.value.map((item) => {
        return {
          target: item.study2,
          source: item.study1,
          value:[item.ldak_gc, item.ldak_gc_p],
          lineStyle:{
            width: Math.min(Math.sqrt(Math.abs(item.ldak_gc))*10, 80),
            color: item.ldak_gc > 0 ? 'red' : 'blue'
          }
        }
      })
      geneCorChart1Init();

      tmp = h2GencorTableData.value.filter(
        (data) => {
          return data.ldsc_gc != null
        }
      )

      genCor2Data.value = tmp.map((item) => {
        return {
          name:item.study2,
          symbolSize: [80, 80],
          value:item.trait2
        }
      })
      if (h2GencorTableData.value.length != 0){
        trait1 = trait.value
      }
      genCor2Data.value.push({
        name:study.value,
        x: 200,
        y: 200,
        symbolSize: [80, 80],
        value: trait1
      })
      
      genCor2Link.value = h2GencorTableData.value.map((item) => {
        return {
          target: item.study2,
          source: item.study1,
          value:[item.ldsc_gc, item.ldsc_gc_p],
          lineStyle:{
            width: Math.min(Math.sqrt(Math.abs(item.ldsc_gc))*10, 80),
            color: item.ldsc_gc > 0 ? 'red' : 'blue'
          }
        }
      })
      geneCorChart2Init();
    })

    watch(study, (newValue, oldValue) => {
      fetchTrait();
      fetchH2Data();
      fetchH2EnrichData();
      fetchTrait2()
      fetchH2GenCorData();
    })

    onMounted(() => {
      fetchStudy()
      fetchTrait();
      fetchH2Data();
      fetchH2EnrichData();
      fetchTrait2()
      fetchH2GenCorData();
    })
    
    return{
      h2Chart,
      h2EnrichChart,
      geneCorChart1,
      geneCorChart2,
      study,
      selectedStudy,
      trait,
      h2TableData,
      h2EnrichTableData,
      currentHEPage,
      currentHGCPage,
      HEpageSize,
      HGCPageSize,
      h2EnrichTotal,
      h2GCTotal,
      HEpagedData,
      h2GencorTableData,
      study2Options,
      studyOptions,
      trait2,
      LDAK_RG,
      LDAK_P,
      LDSC_RG,
      LDSC_P,
      handleHECurrentChange,
      handleHGCCurrentChange,
      handleHESizeChange,
      handleHGCSizeChange,
      filterByTraitOrAuthor,
      reset,
      sortLDAKChange,
      sortGCChange,
      searchStudy,
      fetchStudy 
    }
  }
}
</script>

<style scoped>
  .equal-width-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%
  }
  .bar{
    width: 100%;
    height: 600px;
  }
  .geneCor{
    display: flex
  }
</style>