<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <h2 class="title">
    The Fine mapping results for {{ study }}
  </h2>
  <div class="table_search">
    <div class="table_select">
      <h2 >Select a locus to view the corresponding results:</h2>
      <el-select-v2
        v-model="locus"
        placeholder="input and select a locus"
        filterable
        :options="locusOptions"
        style="width: 240px;margin-left: 10px"
      />
    </div>
  </div>
  <Finemap :study="study" :locus="locus"></Finemap>
  <el-backtop :right="50" :bottom="50" />
</template>

<script>
import { reactive, ref, watch, onMounted, computed, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
import { ElSelectV2, ElBacktop, ElDivider, ElButton, ElSelect, ElOption } from 'element-plus';
import Finemap from '../components/Finemap.vue'


export default {
  name:'FineMapping',
  components:{
    ElSelectV2,
    ElSelect,
    ElOption,
    Finemap,
    ElBacktop,
    ElDivider,
    ElButton
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const study = ref(route.params.study)
    const selectedStudy = ref(route.params.study)
    var locus = ref('')
    var locusOptions = ref([])
    var studyOptions = ref([])

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: 'FineMapping'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchData = async () => {
      try {
        const response = await proxy.$request.get('/getLocus',{ 
          params:{
            study:study.value
        }})
        locusOptions.value = response.data.map((item) => {
          return {
            value:item,
            label:item
          }
        })
        locus.value = response.data[0]
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    watch(study, (newValue, oldValue) => {
      fetchData()
    })

    onMounted(() => {
      fetchStudy()
      fetchData()
    })

    return {
      study,
      selectedStudy,
      locus,
      locusOptions,
      studyOptions,
      searchStudy
    }
  }
}
</script>

<style>
.study_select{
  display: flex;
}
</style>