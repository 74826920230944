<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <h2 style="text-align: center;font-size: 32px;">The TWAS results for {{ study }}</h2>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">Tissue:</div>
      <div>
        <el-select-v2
          v-model="tissue"
          placeholder="input and select a tissue"
          filterable
          :options="tissueOptions"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="table_select">
      <div class="search_title">Gene:</div>
      <div>
        <el-select-v2
          v-model="gene"
          placeholder="input and select a gene"
          filterable
          :options="geneOptions"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="filter">Search</el-button>
      <el-button @click="reset">Reset</el-button>
    </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loading"
        :data="tableData"
        :border="true"
        style="width: 100%; overflow-y: auto"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column prop="study" label="Study" min-width="125" />
        <el-table-column prop="trait" label="Trait" min-width="125">
          <template v-slot:default>
            <div>
              {{ trait }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tissue" label="Tissue" min-width="125" />
        <el-table-column sortable prop="gene" label="Gene" min-width="125">
          <template v-slot="scope">
            <a v-if="scope.row.gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.gene" target="_blank">{{scope.row.gene}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="UTMOST_BETA" label="UTMOST Beta" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="UTMOST_Z" label="UTMOST Z" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="UTMOST_P" label="UTMOST P" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="UTMOST_FDR" label="UTMOST FDR" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="JTI_BETA" label="JTI Beta" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="JTI_Z" label="JTI Z" min-width="125" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="JTI_P" label="JTI P" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="JTI_FDR" label="JTI FDR" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="PREDIXCAN_BETA" label="PrediXcan eQTL Beta" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="PREDIXCAN_Z" label="PrediXcan eQTL Z" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="PREDIXCAN_P" label="PrediXcan eQTL P" min-width="125" :formatter="$utils.NAFormat" />     
        <el-table-column sortable prop="PREDIXCAN_FDR" label="PrediXcan eQTL FDR" min-width="125" :formatter="$utils.NAFormat" />     
      </el-table>

      <el-pagination
        style="float: right;margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="Total"
        :page-sizes="[5,10,20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" 
      /> 
    </div>
  </div>
</template>

<script>
import { ElSelectV2, ElOption, ElTable, ElTableColumn, ElButton, ElDivider, ElInput, ElPagination, ElSelect } from 'element-plus';
import { ref,onMounted,computed, watch, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
export default {
  components: {
    ElSelectV2, 
    ElOption, 
    ElTable, 
    ElTableColumn, 
    ElButton, 
    ElDivider,
    ElInput,
    ElPagination,
    ElSelect
  },
  name:'TWAS',
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    var loading = ref(false)
    var study = ref(route.params.study)
    const selectedStudy = ref(route.params.study)
    var trait = ref('')
    var tableData = ref([])
    var tissue = ref('')
    var tissueOptions = ref([])
    var gene = ref('')
    var geneOptions = ref([])
    var studyOptions = ref([])

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)
    var order = ''
    var prop = ''

    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchTableData()
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      fetchTableData()
    };

    const sortChange = (arg) => {
      order = arg.order
      prop = arg.prop
      fetchTableData()
    }


    const filter = () => {
      fetchTableData()
    }

    const reset = () => {
      gene.value = ''
      tissue.value = ''
      currentPage.value = 1
      fetchTableData()
    }

    const fetchTrait = async () => {
      try {
        const response = await proxy.$request.get('/getTrait',{
          params:{
            study: study.value
          }
        })
        trait.value = response.data
      } catch(error) {
        console.log('fail', error.message)
      }
    }

    const fetchTissue = async () => {
      try{
        const response = await proxy.$request.get('/getTwasTissue',{
          params:{
            study: study.value
          }
        })
        tissueOptions.value = response.data.map(item => ({
          label:item,
          value:item
        }))
      } catch (error){
        console.log('fail', error.message)
      }
    }

    const fetchGene = async () => {
      try{
        const response = await proxy.$request.get('/getTwasGene',{
          params:{
            study: study.value
          }
        })
        geneOptions.value = response.data.map(item => ({
          label:item,
          value:item
        }))
      } catch (error){
        console.log('fail', error.message)
      }
    }

    const fetchTableData = async () => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/pageTwas',{ 
          params:{
            study:study.value,
            tissue: tissue.value,
            gene: gene.value,
            page: currentPage.value,
            pageSize: pageSize.value,
            order: order,
            prop: prop
        }})
        tableData.value = response.data.rows
        Total.value = response.data.total
      } catch (error) {
        console.log('fail', error.message)
      }
      loading.value = false
    }

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: 'TWAS'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    watch(study, (newValue, oldValue) => {
      fetchTrait()
      fetchTissue()
      fetchGene()
      fetchTableData()
    })

    onMounted(() => {
      fetchStudy()
      fetchTrait()
      fetchTissue()
      fetchGene()
      fetchTableData()
    })

    return {
      study,
      selectedStudy,
      trait,
      tissue,
      tissueOptions,
      studyOptions,
      gene,
      geneOptions,
      currentPage,
      pageSize,
      Total,
      loading,
      tableData,

      filter,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
      searchStudy,
      fetchStudy
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>