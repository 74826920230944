<template>
    <div class="header">
      <div class="icon" @click="toHome">
        <img src="../assets/cardiogene.png" alt="">
        <p><span style="color:#a82d26">C</span>ardio<span style="color:#335495">G</span>ene</p>
      </div>
      <ul>
        <li
          v-for="navigate in navigateData"
          :key="navigate.name"
        >
          <router-link class="router" :to="{
            name:navigate.router
          }">
            <component class="bar_icon" :is="navigate.icon"/>
            <strong>
              {{ navigate.name }}
            </strong>
          </router-link>
        </li>
      </ul>
    </div>
</template>

<script>
import { reactive} from 'vue'
import { useRouter } from 'vue-router';  
import { HomeOutlined, AppstoreOutlined, SearchOutlined, DownloadOutlined, FileTextOutlined, WhatsAppOutlined } from '@ant-design/icons-vue'

export default{
  name: 'Banner',
  components:{
    HomeOutlined,
    AppstoreOutlined,
    SearchOutlined,
    DownloadOutlined,
    FileTextOutlined,
    WhatsAppOutlined
  },
  setup(){
    const router = useRouter()

    let navigateData = reactive([{
      name:'Home',
      router:'Home',
      icon:"HomeOutlined"
    },{
      name:'Overview',
      router:'Overview',
      icon:"AppstoreOutlined"
    },{
      name:'Search',
      router:'Search',
      icon:"SearchOutlined"
    },{
      name:'Download',
      router:'Download',
      icon:"DownloadOutlined"
    },{
      name:'Documentation',
      router:'Documentation',
      icon:"FileTextOutlined"
    }])

    const toHome = () => {
      router.push({
        path:'/Home',
      })
    }
  
    return{
      navigateData,
      toHome
    }
  }
}
</script>

<style scoped>
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dcdddd;
    height: 90px;
  }
  .icon{
    /* background-color: blue; */
    display: flex;
    margin-right: 140px;
    text-align: center;
    display: flex;
    align-items: center;
    color: black;
    font-family:sans-serif;
    font-size: 24px;
    /* font-weight: bold; */
    height: 100px;
    width: 100px;
    cursor: pointer;
  }
  ul{
    /* background-color: orange; */
    height: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .router{
    height: 100%;
    display: flex;
    font-family: system-ui;
    font-size: 16px;
    align-items: center;
    padding: 0 20px;
    color: black;
    text-decoration: none; 
    transition: background-color 0.15s ease-in-out,
    color 0.15s ease-in-out;
  }
  .router:hover,.router-link-active{
    background-color: #fff;
    color:#18bc9c
  }
  .bar_icon{
    margin-right: 10px;
  }
</style>