<template>
  <el-tabs type="card">
    <el-tab-pane v-loading="loadingImg1" label="scatter">
      <img :src="scatterplot" alt="scatter" style="width:40%" @load="loadingImg1 = false" @error="loadingImg1 = false">
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg2" label="forest">
      <img :src="forestplot" alt="forest" style="width:40%" @load="loadingImg2 = false" @error="loadingImg2 = false">      
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg3" label="funnel">
      <img :src="funnelplot" alt="funnel" style="width:40%" @load="loadingImg3 = false" @error="loadingImg3 = false">    
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg4" label="ivw leave one out">
      <img :src="ivw_looplot" alt="ivw_loo" style="width:40%" @load="loadingImg4 = false" @error="loadingImg4 = false">    
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg5" label="egger leave one out">
      <img :src="egger_looplot" alt="egger_loo" style="width:40%" @load="loadingImg5 = false" @error="loadingImg5 = false">    
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg6" label="simple mode leave one out">
      <img :src="simple_mode_looplot" alt="sm_loo" style="width:40%" @load="loadingImg6 = false" @error="loadingImg6 = false">    
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg7" label="weighted median leave one out">
      <img :src="weighted_median_looplot" alt="wmd_loo" style="width:40%" @load="loadingImg7 = false" @error="loadingImg7 = false">    
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg8" label="weighted mode leave one out">
      <img :src="weighted_mode_looplot" alt="wm_loo" style="width:40%" @load="loadingImg8 = false" @error="loadingImg8 = false">    
    </el-tab-pane>
  </el-tabs>
  <el-divider />
  <div class="table_search">
    <!-- <div class="table_select">
      <div class="search_title">SNP:</div>
      <el-select-v2
        v-model="snp"
        placeholder="input and select a SNP"
        filterable
        :options="snpOptions"
        style="width: 240px"
      />
    </div> -->
    <!-- <div class="table_select">
      <div class="table_select">
        <div class="search_title">P {{ '<' }}</div>
        <el-input v-model="p" style="width: 240px" type="number" placeholder="input P" />
      </div>        
    </div>
    <div class="search_button">
      <el-button type="primary" @click="filterBySNPorP">Search</el-button>
      <el-button @click="reset">Reset</el-button>
    </div> -->
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loadingTable"
        :data="tableData"
        :border="true"
        style="width: 100%"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column sortable prop="exposureId" label="Exposure Id" min-width="75"/>
        <el-table-column sortable prop="exposureMeSHTerm" label="Exposure MeSH Term" min-width="125"/>
        <el-table-column sortable prop="outcomeId" label="Outcome Id" min-width="125"/>
        <el-table-column sortable prop="outcomeMeSHTerm" label="Outcome MeSH Term" min-width="125"/>
        <el-table-column sortable prop="ivwB" label="IVW Beta" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="ivwSe" label="IVW SE" min-width="75" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="ivwP" label="IVW P" min-width="75" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="eggerB" label="MR (Egger) Beta" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="eggerSe" label="MR (Egger) SE" min-width="125" :formatter="$utils.NAFormat"/> 
        <el-table-column sortable prop="eggerP" label="MR (Egger) P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="smB" label="MR (Simple mode) Beta" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column prop="smSe" label="MR (Simple mode) SE" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="smP" label="MR (Simple mode) P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="wmB" label="MR (Weighted mode) Beta" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="wmSe" label="MR (Weighted mode) SE" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="wmP" label="MR (Weighted mode) P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="wmdB" label="MR (weighted median) Beta" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="wmdSe" label="MR (weighted median) SE" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="wmdP" label="MR (weighted median) P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="ivwHerP" label="IVW Heterogeneity P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column prop="eggerHerP" label="Egger Heterogeneity P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="pleP" label="Horizontal Pleiotropy P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="nsnps" label="#IVs" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="snp" label="IVs" min-width="125"/>
      </el-table>  
      
      <!-- <el-pagination
        style="float: right;margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="Total"
        :page-sizes="[5,10,20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" 
      />  -->
    </div>
  </div>
</template>

<script>
import { ElTabs, ElTabPane, ElSelectV2, ElTable, ElTableColumn, ElButton, ElInput, ElDivider, ElPagination } from 'element-plus';
import { ref, computed, watch, onMounted, getCurrentInstance } from 'vue';

export default {
  name:'MR',
  components:{
    ElTabs, 
    ElTabPane, 
    ElSelectV2,
    ElTable, 
    ElTableColumn, 
    ElButton, 
    ElInput, 
    ElDivider, 
    ElPagination
  },
  props:{
    study: String,
    exposure: String
  },
  setup(props){
    const { proxy } = getCurrentInstance()
    var loadingImg1 = ref(false)
    var loadingImg2 = ref(false)
    var loadingImg3 = ref(false)
    var loadingImg4 = ref(false)
    var loadingImg5 = ref(false)
    var loadingImg6 = ref(false)
    var loadingImg7 = ref(false)
    var loadingImg8 = ref(false)
    var loadingTable = ref(false)
    var scatterplot = ref('')
    var forestplot = ref('')
    var funnelplot = ref('')
    var ivw_looplot = ref('')
    var egger_looplot = ref('')
    var simple_mode_looplot = ref('')
    var weighted_median_looplot = ref('')
    var weighted_mode_looplot = ref('')

    var snp = ref('')
    var snpOptions = ref([])
    var p = ref(null)

    var tableData = ref([])

    var filterBySNPorP = () => {
      fetchData()
    }

    var reset = () => {
      snp.value = ''
      p.value = null
      currentPage.value = 1
      fetchData()
    }

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)
    var order = ''
    var prop = ''

    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchData()
    };
    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      fetchData()
    };

    const sortChange = (arg) => {
      order = arg.order
      prop = arg.prop
      fetchData()
    }

    const fetchData = async () => {
      loadingTable.value = true
      try {
        const response = await proxy.$request.get('/getTSMR',{ 
          params:{
            outcome_id:props.study,
            exposure_id:props.exposure
        }})
        console.log('2',response.data)
        tableData.value = response.data
        loadingTable.value = false
      } catch (error) {
        console.log('fail', error.message)
        loadingTable.value = false
      }
    }

    const fetchPNG = () => {
      loadingImg1.value = true
      loadingImg2.value = true
      loadingImg3.value = true
      loadingImg4.value = true
      loadingImg5.value = true
      loadingImg6.value = true
      loadingImg7.value = true
      loadingImg8.value = true
      scatterplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/scatterplot.png'
      forestplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/forestplot.png'
      funnelplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/funnelplot.png'
      ivw_looplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/ivw_looplot.png'
      egger_looplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/egger_looplot.png'
      simple_mode_looplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/simple_mode_looplot.png'
      weighted_median_looplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/weighted_median_looplot.png'
      weighted_mode_looplot.value = 'http://43.154.207.136/png/mr/'+props.study+'/'+props.exposure+'/weighted_mode_looplot.png'
    }

    watch(() => props.exposure, (newValue, oldValue) => {
      currentPage.value = 1
      snp.value = ''
      p.value = null
      fetchData()
      fetchPNG()
    })

    watch(() => props.study, (newValue, oldValue) => {
      currentPage.value = 1
      snp.value = ''
      p.value = null
      fetchData()
      fetchPNG()
    })

    onMounted(() => {
      fetchData()
      fetchPNG()
    })

    return {
      loadingTable,
      loadingImg1,
      loadingImg2,
      loadingImg3,
      loadingImg4,
      loadingImg5,
      loadingImg6,
      loadingImg7,
      loadingImg8,
      scatterplot,
      forestplot,
      funnelplot,
      ivw_looplot,
      egger_looplot,
      simple_mode_looplot,
      weighted_median_looplot,
      weighted_mode_looplot,
      snp,
      snpOptions,
      p,
      tableData,
      currentPage,
      pageSize,
      Total,

      filterBySNPorP,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>