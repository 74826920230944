<template>
  <h2 style="text-align: center;font-size: 32px;">Search results for keyword: {{ displayWord }}</h2>
  <el-divider />
  <div class="search">
      <div class="search_box">
        <el-input
          v-model="selectWord"
          style="width: 100%"
          placeholder="Enter Gene or SNP, Example: APOE"
          @keyup.enter = "Search"
          clearable
          size="large"
        />
      </div>
      <button class="search_btn" @click="Search">
        <SearchOutlined class="search_icon"/>
      </button>
  </div>
  <div style="display: flex;justify-content: center;">
    <div style="width: 52%;">
      <div class="radio_select">
        <div>Search by:</div>
        <el-radio-group style="margin-left: 20px;" v-model="searchKey">
          <el-radio :value="'Gene'">Gene</el-radio>
          <el-radio :value="'SNP'">SNP</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
  <el-divider />
  <div class="table_search">
      <div class="table_select">
        <div class="search_title">Trait:</div>
        <el-select
          v-model="trait"
          placeholder="select a trait"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in traitOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="table_select">
        <div class="search_title">Method:</div>
        <el-select
          v-model="method"
          placeholder="select a method"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in methodOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="search_button">
        <el-button type="primary" @click="filterByTraitorMethod">Search</el-button>
        <el-button @click="reset">Reset</el-button>
      </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loading"
        :data="tableData"
        :border="true"
        style="width: 100%; overflow-y: auto"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
      >
        <el-table-column prop="study" label="Study" min-width="125" />
        <el-table-column prop="trait" label="Trait" min-width="125" />
        <el-table-column prop="tissue" label="Tissue" min-width="125" :formatter="tissueFormat" />
        <el-table-column prop="gene" label="Gene" min-width="125" >
          <template v-slot="scope">
            <a v-if="scope.row.gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.gene" target="_blank">{{scope.row.gene}}</a>
          </template>
        </el-table-column>     
        <el-table-column prop="snp" label="SNP" min-width="125" :formatter="SNPFormat">
          <template v-slot="scope">
            <a v-if="scope.row.snp" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.snp" target="_blank">{{scope.row.snp}}</a>
          </template>
        </el-table-column>    
        <el-table-column prop="statisticsName" label="Statistics Name" min-width="125" />
        <el-table-column prop="statisticsValue" label="Statistics Value" min-width="125" :formatter="$utils.NAFormat" />   
        <el-table-column prop="method" label="Method" min-width="125">
          <!-- <template v-slot="scope">
            <router-link
              class="pmid"
              :key="scope.row.Method"
              :to="{
                name:scope.row.Method == 'FINEMAP' ? 'FineMapping' : scope.row.Method,
                params:{
                  study:scope.row.Study
                }
              }"
              target="_blank"
              >
              {{ scope.row.Method }}
            </router-link>
          </template> -->
        </el-table-column>
      </el-table>

      <el-pagination
        style="float: right;margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="Total"
        :page-sizes="[5,10,20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" 
      /> 
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, getCurrentInstance } from 'vue'
import { ElSelect, ElOption, ElButton, ElInput, ElDivider, ElTable, ElTableColumn, ElPagination, ElRadioGroup, ElRadio, ElMessage } from 'element-plus';
import {CloseCircleOutlined, SearchOutlined} from '@ant-design/icons-vue'
import { useRoute } from 'vue-router';

export default {
  name:'Search',
  components:{
    ElSelect, 
    ElOption, 
    ElButton,
    ElInput,
    ElDivider,
    ElTable, 
    ElTableColumn, 
    ElPagination,
    CloseCircleOutlined,
    SearchOutlined,
    ElRadioGroup, 
    ElRadio,
    ElMessage
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    var loading = ref(false)
    var searchWord = ''
    var selectWord = ref('')
    var displayWord = ref('')
    var searchKey = ref('Gene')
    var isSearching = ref(false)
    var method = ref('')
    var methodOptions = ref([])
    var trait = ref('')
    var traitOptions = ref([])

    function cleanSearch(){
      selectWord.value = ''
    }

    var tableData = ref([])

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)

    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchData()
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      fetchData()
    };

    const fetchTrait = async () => {
      try {
        if(searchKey.value == 'Gene'){
          const response = await proxy.$request.get('/getTraitByGene',{ 
            params:{
              gene: searchWord.trim(),
          }})
          traitOptions.value = response.data.map(item => ({
            value: item,
            label: item
          }))
        }else{
          const response = await proxy.$request.get('/getTraitBySnp',{ 
            params:{
              snp: searchWord.trim(),
          }})
          traitOptions.value = response.data.map(item => ({
            value: item,
            label: item
          }))
        }
      } catch(error) {
        console.log('fail', error.message)
      }
    }

    const fetchMethod = async () => {
      try {
        if(searchKey.value == 'Gene'){
          const response = await proxy.$request.get('/getMethodByGene',{ 
            params:{
              gene: searchWord.trim(),
          }})
          methodOptions.value = response.data.map(item => ({
            value: item,
            label: item
          }))
        }else{
          const response = await proxy.$request.get('/getMethodBySnp',{ 
            params:{
              snp: searchWord.trim(),
          }})
          methodOptions.value = response.data.map(item => ({
            value: item,
            label: item
          }))
        }
      } catch(error) {
        console.log('fail', error.message)
      }
    }

    const fetchData = async () => {
      if (searchWord.trim() == ''){
        ElMessage({
          message: 'searchWord can not be null!',
          type: 'warning'
        })
        return
      }
      loading.value = true
      try {
        if(searchKey.value == 'Gene'){
          const response = await proxy.$request.get('/getSearchByGene',{ 
            params:{
              gene: searchWord.trim(),
              trait: trait.value,
              method: method.value,
              page: currentPage.value,
              pageSize: pageSize.value
          }})
          tableData.value = response.data.rows
          Total.value = response.data.total
        }else{
          const response = await proxy.$request.get('/getSearchBySnp',{ 
            params:{
              snp: searchWord.trim(),
              trait: trait.value,
              method: method.value,
              page: currentPage.value,
              pageSize: pageSize.value
          }})
          tableData.value = response.data.rows
          Total.value = response.data.total
        }
        if(Total.value == 0){
          ElMessage({
            message: 'No results for '+searchKey.value.toLowerCase()+' '+searchWord.trim()+'.'
          })
        }
        isSearching.value = false
        loading.value = false
      } catch (error) {
        console.log('fail', error.message)
      }
    }
    
    const Search = () => {
      currentPage.value = 1
      if (isSearching.value) {
        return;
      }
      if (selectWord.value.trim() == ''){
        ElMessage({
          message: 'searchWord can not be null!',
          type: 'warning'
        })
        isSearching.value = false
      }
      else{
        searchWord = selectWord.value
        fetchData()
        fetchTrait()
        fetchMethod()
        displayWord.value = searchWord
      }
    }
    
    const tissueFormat = (row) => {
      let showProp = null
      row.tissue ? showProp = row.tissue : showProp = '-'
      return showProp
    }

    const SNPFormat = (row) => {
      let showProp = null
      row.SNP ? showProp = row.SNP : showProp = '-'
      return showProp
    }

    var filterByTraitorMethod = () => {
      currentPage.value = 1
      fetchData()
      // filter_table_data.value = table_data.value.filter(
      //   (data) => {
      //     return (!trait.value || data.Trait.toLowerCase() === trait.value.toLowerCase()) 
      //     &&
      //     (!method.value || data.Method === method.value)
      //   }
      // )
      // Total.value = filter_table_data.value.length
    }

    var reset = () => {
      trait.value = ''
      method.value = ''
      fetchData()
    }

    // const methodFormat = (row) => {
    //   let showProp = null
    //   if(row.StatisticsName == "ABF PP" || row.StatisticsName == "FINEMAP PP" || row.StatisticsName == "SuSiE PP"){
    //     showProp = 'FINEMAP'
    //   }else if(row.StatisticsName == "JTI P" || row.StatisticsName == "UTMOST P" || row.StatisticsName == "PrediXcan P"){
    //     showProp = "TWAS"
    //   }else if (row.StatisticsName == "SMR_eqtl P" || row.StatisticsName == "SMR_sqtl P"){
    //     showProp = "SMR"
    //   }else if (row.StatisticsName == "COLOC pp_h4_abf"){
    //     showProp = "COLOC"
    //   }else{
    //     showProp = "MAGMA"
    //   }
    //   return showProp
    // }

    const filterhandler = (value, row, column) => {
      const property = column['property']
      return row[property] == value
    }

    onMounted(() => {
      if(route.query.Gene){
        selectWord.value = route.query.Gene,
        searchKey.value = 'Gene',
        Search()
      }else if(route.query.Variant){
        selectWord.value = route.query.Variant,
        searchKey.value = 'SNP',
        Search()
      }
    })

    return {
      selectWord,
      displayWord,
      searchKey,
      loading,
      method,
      methodOptions,
      trait,
      traitOptions,

      currentPage,
      pageSize,
      Total,
      tableData,

      handleCurrentChange,
      handleSizeChange,
      cleanSearch,
      Search,
      tissueFormat,
      SNPFormat,
      // methodFormat,
      filterByTraitorMethod,
      reset,
      filterhandler
    }
  }
}
</script>

<style scoped>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .search{
    display: flex;
    justify-content: center;
    text-align: center;
    align-content: center;
  }
  .search_box{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 45%;
  }
  .radio_select{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  input{
    width:100%;
    height: 25px;
    font-size: 16px;
    border: none;
    outline:none
  }
  input:focus{
    outline:none
  }
  input::placeholder{
    color: #d0d0d0;
  }
  .clean_icon{
    color:#b2b2b2
  }
  .clean_icon:hover{
    color:#606060
  }
  .search_icon{
    color: white;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
  .search_btn{
    border: 1px solid #0c64b6;
    border-radius: 0 2px 2px 0;
    background-color: #0c64b6;
    transition: border 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
    width: 50px;
  }
  .search_btn:hover{
    border: 1px solid #127fe5;
    background-color: #127fe5;
    cursor: pointer;
  }
</style>