<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <h2 class="title">
    The MAGMA results for {{ study }}
  </h2>
  <div class="figure_magma">
    <div v-loading="loadingImg1">
      <img :src="manhattan" alt="manhattan"  @load="loadingImg1 = false" @error="loadingImg1 = false">
    </div>
    <div v-loading="loadingImg2">
      <img :src="qq" alt="qq"  @load="loadingImg2 = false" @error="loadingImg2 = false">
    </div>
  </div>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">Gene:</div>
      <div>
        <el-select-v2
          v-model="gene"
          placeholder="input and select a gene"
          filterable
          :options="geneOptions"
          style="width: 240px"
        />
      </div>
    </div>
    <div>
        <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
        <el-button @click="reset">Reset</el-button>
      </div>
  </div>
  <div>
    <div  class="table_container">
      <el-table 
        v-loading="loading"
        :data="tableData"
        :border="true"
        style="width: 100%"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column prop="study" label="Study" />
        <el-table-column prop="trait" label="Trait">
          <template v-slot:default>
            <div>
              {{ trait }}
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable prop="gene" label="Gene Symbol">
          <template v-slot="scope">
            <a class="pmid" :href="'https://www.ncbi.nlm.nih.gov/gene/'+scope.row.gene_id" target="_blank">{{scope.row.gene}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="gene_id" label="Gene ID" >
          <template v-slot="scope">
            <a class="pmid" :href="'https://www.ncbi.nlm.nih.gov/gene/'+scope.row.gene_id" target="_blank">{{scope.row.gene_id}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="p" label="P" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="n" label="#Sample" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="nsnps" label="#SNP" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="chr" label="Chromosome" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="start" label="Start" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="stop" label="Stop" :formatter="$utils.NAFormat" />
      </el-table>

      <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="Total"
          :page-sizes="[5,10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, onMounted, computed, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
import { ElSelect, ElOption, ElTable, ElTableColumn, ElButton, ElDivider, ElPagination, ElSelectV2 } from 'element-plus';
export default {
  name:'MAGMA',
  components:{
    ElSelect,
    ElOption,
    ElTable,
    ElTableColumn,
    ElButton,
    ElDivider,
    ElPagination,
    ElSelectV2
  },
  setup(){
    const { proxy } = getCurrentInstance()
    var loading = ref(false)
    const route = useRoute()
    var study = ref(route.params.study)
    const selectedStudy = ref(route.params.study)
    var trait = ref('')
    var manhattan = ref('')
    var qq = ref('')
    var tableData = ref([])
    var gene=ref('')
    var geneOptions = ref([])
    var studyOptions = ref([])
    var loadingImg1 = ref(false)
    var loadingImg2 = ref(false)

    var reset = () => {
      gene.value = ''
      currentPage.value = 1
      fetchTableData()
    }

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)
    var prop = ''
    var order = ''

    const sortChange = (arg) => {
      prop = arg.prop
      order = arg.order
      fetchTableData()
    }

    const filterByTraitOrAuthor = () => {
      fetchTableData()
    }

    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchTableData()
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      fetchTableData()
    };

    const fetchTrait = async () => {
      try {
        const response = await proxy.$request.get('/getTrait',{
          params:{
            study: study.value
          }
        })
        trait.value = response.data
      } catch(error) {
        console.log('fail', error.message)
      }
    }

    const fetchTableData = async () => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/pageMagma',{ 
          params:{
            study:study.value,
            gene: gene.value,
            page: currentPage.value,
            pageSize: pageSize.value,
            order: order,
            prop: prop
        }})
        tableData.value = response.data.rows
        Total.value = response.data.total
        loading.value = false
      } catch (error) {
        console.log('fail', error.message)
      }
      loading.value = false
    }

    const fetchGeneListData = async () => {
      try {
        const response = await proxy.$request.get('/getGene',{ 
          params:{
            study:study.value
        }})
        geneOptions.value = response.data.map(item => ({
          value: item,
          label: item
        }))
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchPNG = () => {
      loadingImg1.value = true
      loadingImg2.value = true
      manhattan.value = 'http://43.154.207.136/png/magma/'+study.value+'/man.png'
      qq.value = 'http://43.154.207.136/png/magma/'+study.value+'/qq.png'
    }

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: 'MAGMA'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    watch(study, (newValue, oldValue) => {
      fetchTrait()
      fetchTableData()
      fetchGeneListData()
      fetchPNG()
    })
    
    onMounted(() => {
      fetchStudy()
      fetchTrait()
      fetchTableData()
      fetchGeneListData()
      fetchPNG()
    })
    
    return {
      loading,
      loadingImg1,
      loadingImg2,
      route,
      study,
      selectedStudy,
      trait,
      manhattan,
      qq,
      tableData,
      currentPage,
      pageSize,
      Total,
      gene,
      geneOptions,
      studyOptions,
      filterByTraitOrAuthor,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
      searchStudy,
      fetchStudy
    }
  }
}
</script>
<style scoped>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .title{
    font-weight: 600;
    font-size: 30px;
    line-height: 1.35;
    font-family: "Trebuchet MS", Arial, sans-serif;
    text-align: center;
  }
  .figure_magma{
    display: flex;
  }
  img{
    width: 100%;
  }
  .table_container{
    padding: 0 24px 24px 24px;
    overflow-x: auto;
  }
  table{
    border-collapse: collapse;
    border-bottom: 1px solid rgba(0, 0, 0, .06);
    border-left: 1px solid rgba(0, 0, 0, .06);
    width: 100%;
    font-family: "Trebuchet MS", Arial, sans-serif;;
    font-size: 14px;
    text-align: left;
    line-height: 1.5;
  }
  tr{
    border-top: 1px solid rgba(0, 0, 0, .06);
  }
  th, td{
    border-right: 1px solid rgba(0, 0, 0, .06);
    padding: 12px 8px;
  }
  th{
    background-color: rgba(0, 0, 0, .02);
    font-family: sans-serif;
  }
  .table_search{
    display: flex;
    margin-bottom: 30px;
  }
  .table_select{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .search_title{
    margin-right: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
</style>