<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <h2 class="title">
    The GARFIELD results for {{ study }}
  </h2>
  <el-tabs type="card">
    <el-tab-pane v-loading="loadingImg1" label="Hotspots">
      <img :src="Hotspots" alt="Hotspots" style="width:60%" @load="loadingImg1 = false" @error="loadingImg1 = false">
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg2" label="Peaks">
      <img :src="Peaks" alt="Peaks" style="width:60%" @load="loadingImg2 = false" @error="loadingImg2 = false">      
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg3" label="chromatinStates">
      <img :src="chromatinStates" alt="chromatinStates" style="width:60%" @load="loadingImg3 = false" @error="loadingImg3 = false">    
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg4" label="FAIRE">
      <img :src="FAIRE" alt="FAIRE" style="width:60%" @load="loadingImg4 = false" @error="loadingImg4 = false">
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg5" label="Footprints">
      <img :src="Footprints" alt="Footprints" style="width:60%" @load="loadingImg5 = false" @error="loadingImg5 = false">
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg6" label="Genic">
      <img :src="Genic" alt="Genic" style="width:60%" @load="loadingImg6 = false" @error="loadingImg6 = false">
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg7" label="histoneModifications">
      <img :src="histoneModifications" alt="histoneModifications" style="width:60%" @load="loadingImg7 = false" @error="loadingImg7 = false">
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg8" label="TFBS">
      <img :src="TFBS" alt="TFBS" style="width:60%" @load="loadingImg8 = false" @error="loadingImg8 = false">
    </el-tab-pane>
  </el-tabs>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">Cell Type:</div>
      <div>
        <el-select
          v-model="cellType"
          placeholder="input and select a cell type"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in cellTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="table_select">
      <div class="search_title">Tissue:</div>
      <div>
        <el-select
          v-model="tissue"
          placeholder="input and select a tissue"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in tissueOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="table_select">
      <div class="search_title">OR  ></div>
      <el-input v-model="or" style="width: 240px" type="number" placeholder="input OR" />
    </div>
    <div class="table_select">
      <div class="search_title">P {{ '<' }}</div>
      <el-input v-model="p" style="width: 240px" type="number" placeholder="input P" />
    </div>
    <div class="search_button">
      <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
      <el-button @click="reset">Reset</el-button>
    </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loadingTable"
        :data="tableData"
        :border="true"
        style="width: 100%; overflow-y: auto"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column prop="study" label="Study" min-width="125" />
        <el-table-column prop="trait" label="Trait" min-width="125" >
          <template v-slot:default>
            <div>
              {{ trait }}
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable prop="p_thresh" label="P Threshold" min-width="125" />
        <el-table-column sortable prop="OR" label="OR" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="p_value" label="P" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="beta" label="Beta" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="SE" label="SE" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="CI95_lower" label="95% CI Lower" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="CI95_upper" label="95% CI Upper" min-width="125" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="n_annot_thresh" label="NAnnotThesh" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="n_annot" label="NAnnot" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="n_thresh" label="NThresh" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="n" label="#Sample" min-width="125" :formatter="$utils.NAFormat" />
        <el-table-column prop="cell_type" label="CellType" min-width="125" />
        <el-table-column prop="tissue" label="Tissue" min-width="125" />
        <el-table-column prop="type" label="Type" min-width="125" />        
      </el-table>

      <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="Total"
          :page-sizes="[5,10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
    </div>
  </div>

</template>

<script>
import { ElTabs, ElTabPane, ElSelect, ElOption, ElTable, ElTableColumn, ElButton, ElInput, ElDivider, ElPagination } from 'element-plus';
import { reactive, ref, computed, watch, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name:'GARFIELD',
  components:{
    ElTabs,
    ElTabPane,
    ElSelect,
    ElOption,
    ElTable,
    ElTableColumn,
    ElButton,
    ElInput,
    ElDivider,
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    var loadingImg1 = ref(false)
    var loadingImg2 = ref(false)
    var loadingImg3 = ref(false)
    var loadingImg4 = ref(false)
    var loadingImg5 = ref(false)
    var loadingImg6 = ref(false)
    var loadingImg7 = ref(false)
    var loadingImg8 = ref(false)
    var loadingTable = ref(false)
    var route = useRoute()
    var study = ref(route.params.study)
    const selectedStudy = ref(route.params.study)
    var trait = ref('')
    var Hotspots = ref('')
    var Peaks = ref('')
    var chromatinStates = ref('')
    var FAIRE = ref('')
    var Footprints = ref('')
    var Genic = ref('')
    var histoneModifications = ref('')
    var TFBS = ref('')

    var or = ref(null)
    var p = ref(null)
    var cellType = ref('')
    var tissue = ref('')
    var studyOptions = ref([])
    var cellTypeOptions = ref([])
    var tissueOptions = ref([])
    var prop = ''
    var order = ''

    var tableData = ref([])

    var reset = () => {
      cellType.value = ''
      tissue.value = ''
      or.value = ''
      p.value = ''
      currentPage.value = 1
      fetchTableData()
    }

    const sortChange = (arg) => {
      prop = arg.prop
      order = arg.order
      fetchTableData()
    }

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)

    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchTableData()
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      currentPage.value = 1;
      fetchTableData()
    };

    const filterByTraitOrAuthor = () => {
      fetchTableData()
    }

    const fetchCellType = async () => {
      try{
        const response = await proxy.$request.get('/getCellType',{
          params:{
            study: study.value
          }
        })
        cellTypeOptions.value = response.data.map(item => ({
          label:item,
          value:item
        }))
      } catch (error){
        console.log('fail', error.message)
      }
    }

    const fetchTissue = async () => {
      try{
        const response = await proxy.$request.get('/getTissue',{
          params:{
            study: study.value
          }
        })
        tissueOptions.value = response.data.map(item => ({
          label:item,
          value:item
        }))
      } catch (error){
        console.log('fail', error.message)
      }
    }

    const fetchTrait = async () => {
      try {
        const response = await proxy.$request.get('/getTrait',{
          params:{
            study: study.value
          }
        })
        trait.value = response.data
      } catch(error) {
        console.log('fail', error.message)
      }
    }

    const fetchTableData = async () => {
      loadingTable.value = true
      try {
        const response = await proxy.$request.get('/pageGarfield',{ 
          params:{
            study: study.value,
            cellType: cellType.value,
            tissue: tissue.value,
            OR: or.value,
            pValue: p.value,
            page: currentPage.value,
            pageSize: pageSize.value,
            prop: prop,
            order: order
        }})
        tableData.value = response.data.rows
        Total.value = response.data.total
      } catch (error) {
        console.log('fail', error.message)
      }
      loadingTable.value = false
    }

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: 'GARFIELD'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchPNG = () => {
      loadingImg1.value = true
      loadingImg2.value = true
      loadingImg3.value = true
      loadingImg4.value = true
      loadingImg5.value = true
      loadingImg6.value = true
      loadingImg7.value = true
      loadingImg8.value = true
      Hotspots.value = 'http://43.154.207.136/png/garfield/'+study.value+'/Hotspots.png'
      Peaks.value = 'http://43.154.207.136/png/garfield/'+study.value+'/Peaks.png'
      chromatinStates.value = 'http://43.154.207.136/png/garfield/'+study.value+'/Chromatin_States.png'
      FAIRE.value = 'http://43.154.207.136/png/garfield/'+study.value+'/FAIRE.png'
      Footprints.value = 'http://43.154.207.136/png/garfield/'+study.value+'/Footprints.png'
      Genic.value = 'http://43.154.207.136/png/garfield/'+study.value+'/Genic.png'
      histoneModifications.value = 'http://43.154.207.136/png/garfield/'+study.value+'/Histone_Modifications.png'
      TFBS.value = 'http://43.154.207.136/png/garfield/'+study.value+'/TFBS.png'
    }
    
    watch(study, (newValue, oldValue) => {
      fetchTrait()
      fetchTableData()
      fetchCellType()
      fetchTissue()
      fetchPNG()
    })

    onMounted(() => {
      fetchStudy()
      fetchTrait()
      fetchTableData()
      fetchCellType()
      fetchTissue()
      fetchPNG()
    })

    return{
      loadingTable,
      loadingImg1,
      loadingImg2,
      loadingImg3,
      loadingImg4,
      loadingImg5,
      loadingImg6,
      loadingImg7,
      loadingImg8,
      study,
      selectedStudy ,
      trait,
      Hotspots,
      Peaks,
      chromatinStates,
      FAIRE,
      Footprints,
      Genic,
      histoneModifications,
      TFBS,
      or,
      p,
      cellType,
      tissue,
      studyOptions,
      cellTypeOptions,
      tissueOptions,
      currentPage,
      pageSize,
      Total,
      tableData,
      filterByTraitOrAuthor,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
      searchStudy,
    }
  }
}
</script>

<style>
  .title{
    font-weight: 600;
    font-size: 32px;
    line-height: 1.35;
    font-family: "Trebuchet MS", Arial, sans-serif;
    text-align: center;
  }
  .el-tab-pane{
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;
  }
  img{
    width: 75%;
  }
  .table_search{
    display: flex;
    margin-bottom: 30px;
  }
  .table_select{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .search_title{
    margin-right: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .table_container{
    padding: 0 24px 24px 24px;
    overflow-x: auto;
  }
</style>