export default{
  NAFormat(row,column,value){
    let tmp
    tmp = value === null ? '-' : value === "" ? '-' : value
    if (typeof(tmp) == 'number'){
      if(Number.isInteger(tmp)){
        tmp = tmp
      }else if(tmp > 0.0001 || tmp < -0.0001){
        tmp = tmp.toFixed(4)
      }else{
        tmp = tmp.toExponential(4)
      }
    }
    return tmp;
  }
}