<template>
  <div class="container">
    <table class="table">
      <colgroup>
        <col style="width: 150px;">
        <col style="width: 400px;">
        <col style="width: 100px;">
        <col style="width: 50px;">
        <col style="width: 100px;">
      </colgroup>
      <thead class="table-head">
        <tr>
          <th>Description</th>
          <th >Details</th>
          <th>Format</th>
          <th>Size</th>
          <th>Download Link</th>
        </tr>
      </thead>
    <tbody>
      <tr v-for="(item, index) in tableData" :key="index">
        <td>{{ item.description }}</td>
        <td>{{ item.detail }}</td>
        <td>tab separated file</td>
        <td>{{ item.size }}</td>
        <td>
          <a :href="item.url"  :download="item.description" target="_blank">Click to Download</a>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from 'vue'


export default {
  name:'Download',
  setup(){
    const { proxy } = getCurrentInstance()
    var tableData = ref([])

    const getdownloadInfo = async () => {
      try {
        const response = await proxy.$request.get('/getDownloadInfo')
        tableData.value = response.data
        console.log(tableData.value)
      } catch (error) {
        console.log('fail', error.message)
      }
    }
    

    onMounted(() => {
      getdownloadInfo()
    })

    return {
        tableData,
      }
  }
}
</script>

<style scoped>
  a{
    text-decoration: none;
  }
  .container{
    display: flex;
    justify-content: center;
  }
  .table-head{
    border-top: solid 2px black;
    border-bottom: solid 2px black;
  }
  .table {
      width: 75%;
      border-collapse: collapse;
      margin-bottom: 40px;
      border-bottom: solid 2px black;
    }

    .table th, .table td {
      padding: 8px;
      text-align: start;
      line-height: 2;
    }
    .table td {
      font-size: 14px;
    }
</style>