<template>
  <div class="body">
    <Banner></Banner>
    <div class="container">
      <router-view></router-view>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Home from './pages/Home.vue'
import Banner from './components/Banner.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Home,
    Banner,
    Footer
  }
}
</script>

<style scoped>
  .body{
    min-width: 1080px;
  }
  .container{
    padding: 24px 60px 0 60px;
    /* background-color: blue; */
  }
</style>
