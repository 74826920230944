<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <h2 style="text-align: center;font-size: 32px;">The SMR results for {{ study }}</h2>
  <div v-loading="Eloading" v-show="showEqtl" class="SMReqtl">
    <el-divider />
    <h2 style="text-align: center;font-size: 32px;">Genes mediating {{ study }} by SMR analysis on eQTL</h2>
    <div ref="SMREqtlChart" class="bar"></div>
    <div>
      <div class="table_search">
        <div class="table_select">
          <div class="search_title">Tissue:</div>
          <div>
            <el-select
              v-model="eqtlTissue"
              placeholder="input and select a tissue"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtlTissueOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">Gene:</div>
          <div>
            <el-select
              v-model="eqtlGene"
              placeholder="input and select a gene"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtlGeneOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">Top SNP:</div>
          <div>
            <el-select
              v-model="eqtlTopSNP"
              placeholder="input and select a top SNP"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtlTopSNPOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="search_button">
            <el-button type="primary" @click="filterEqtl">Search</el-button>
            <el-button @click="resetEqtl">Reset</el-button>
          </div>
      </div>
      <div class="table_container">
        <el-table 
          :data="eqtlPagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortEqtlChange"
        >
          <el-table-column prop="study" label="Study" min-width="150" />
          <el-table-column prop="study" label="Trait" min-width="150">
            <template v-slot:default>
            <div>
              {{ trait }}
            </div>
          </template>
          </el-table-column>
          <el-table-column prop="tissue" label="Tissue" min-width="150" />
          <el-table-column sortable prop="gene" label="Gene" min-width="150">
            <template v-slot="scope">
              <a v-if="scope.row.gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.gene" target="_blank">{{scope.row.gene}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="topSNP" label="Top SNP" min-width="150">
            <template v-slot="scope">
              <a v-if="scope.row.topSNP" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.topSNP" target="_blank">{{scope.row.topSNP}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="topSNPChr" label="Top SNP Chr" min-width="150" />
          <el-table-column sortable prop="topSNPBp" label="Top SNP bp" min-width="150" />
          <el-table-column sortable prop="a1" label="A1" min-width="150" />
          <el-table-column sortable prop="a2" label="A2" min-width="150" />
          <el-table-column sortable prop="freq" label="Frequency" min-width="150" :formatter="$utils.NAFormat" />
          <el-table-column sortable prop="bgwas" label="GWAS Beta" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="seGWAS" label="GWAS Beta SE" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="pgwas" label="GWAS P Value" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="beqtl" label="eQTL Beta" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="seEQTL" label="eQTL Beta SE" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="peqtl" label="eQTL P Value" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="bsmr" label="SMR Beta" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="seSMR" label="SMR Beta SE" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="psmr" label="SMR P Value" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="fdr" label="SMR FDR" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="pheidi" label="HEIDI P Value" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="nsnpHEIDI" label="HEIDI SNP" min-width="150" :formatter="$utils.NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleEqtlSizeChange"
          @current-change="handleEqtlCurrentChange"
          :current-page="eqtlCurrentPage"
          :total="eqtlTotal"
          :page-sizes="[5,10,20]"
          :page-size="eqtlPageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
      </div>
    </div>
  </div>
  <div v-loading="Sloading" v-show="showSqtl" class="SMRsqtl">
    <el-divider />
    <h2 style="text-align: center;font-size: 32px;">Genes mediating {{ study }} by SMR analysis on sQTL</h2>
    <div ref="SMRSqtlChart" class="bar"></div>
    <div>
      <div class="table_search">
      <div class="table_select">
        <div class="search_title">Tissue:</div>
        <div>
          <el-select
            v-model="sqtlTissue"
            placeholder="input and select a tissue"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in sqtlTissueOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="table_select">
        <div class="search_title">Gene:</div>
        <div>
          <el-select
            v-model="sqtlGene"
            placeholder="input and select a gene"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in sqtlGeneOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="table_select">
        <div class="search_title">Top SNP:</div>
        <div>
          <el-select
            v-model="sqtlTopSNP"
            placeholder="input and select a top SNP"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in sqtlTopSNPOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="search_button">
          <el-button type="primary" @click="filterSqtl">Search</el-button>
          <el-button @click="resetSqtl">Reset</el-button>
        </div>
    </div>
      <div class="table_container">
        <el-table 
          :data="sqtlPagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortSqtlChange"
        >
        <el-table-column prop="study" label="Study" min-width="150" />
        <el-table-column prop="study" label="Trait" min-width="150">
          <template v-slot:default>
            <div>
              {{ trait }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tissue" label="Tissue" min-width="150" />
        <el-table-column sortable prop="gene" label="Gene" min-width="150">
          <template v-slot="scope">
            <a v-if="scope.row.gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.gene" target="_blank">{{scope.row.gene}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="topSNP" label="Top SNP" min-width="150">
          <template v-slot="scope">
            <a v-if="scope.row.topSNP" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.topSNP" target="_blank">{{scope.row.topSNP}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="topSNPChr" label="Top SNP chr" min-width="150" />
        <el-table-column sortable prop="topSNPBp" label="Top SNP bp" min-width="150" />
        <el-table-column sortable prop="a1" label="A1" min-width="150" />
        <el-table-column sortable prop="a2" label="A2" min-width="150" />
        <el-table-column sortable prop="freq" label="Frequency" min-width="150" :formatter="$utils.NAFormat" />
        <el-table-column sortable prop="bgwas" label="GWAS Beta" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="seGWAS" label="GWAS Beta SE" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="pgwas" label="GWAS P Value" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="beqtl" label="eQTL Beta" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="seEQTL" label="eQTL Beta SE" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="peqtl" label="eQTL P Value" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="bsmr" label="SMR Beta" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="seSMR" label="SMR Beta SE" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="psmr" label="SMR P Value" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="fdr" label="SMR FDR" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="pheidi" label="HEIDI P Value" min-width="150" :formatter="$utils.NAFormat" /> 
        <el-table-column sortable prop="nsnpHEIDI" label="HEIDI SNP" min-width="150" :formatter="$utils.NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSqtlSizeChange"
          @current-change="handleSqtlCurrentChange"
          :current-page="sqtlCurrentPage"
          :total="sqtlTotal"
          :page-sizes="[5,10,20]"
          :page-size="sqtlPageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ElDivider, ElTable, ElTableColumn, ElSelect, ElOption, ElButton, ElInput, ElPagination } from 'element-plus';
import * as echarts from 'echarts'
import { ref, onMounted,computed, watch, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';

export default {
  name:'SMR',
  components: {
    ElDivider,
    ElTable, 
    ElTableColumn,
    ElSelect, 
    ElOption, 
    ElButton, 
    ElInput, 
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const study = ref(route.params.study)
    var trait = ref('')
    const selectedStudy = ref(route.params.study)
    const SMREqtlChart = ref(null)
    const SMRSqtlChart = ref(null)
    var eqtlData = ref([])
    var eqtlFigData = ref(null)
    var showEqtl = ref(true)
    var showSqtl = ref(true)
    var Eloading = ref(false)
    var Sloading = ref(false)

    var eqtlGeneList = ref([])
    // prettier-ignore
    var eqtlTissueList = ref([])

    var SMReqtlChartInit = () => {
      let Chart = echarts.getInstanceByDom(SMREqtlChart.value);
      if(Chart == null){
        Chart = echarts.init(SMREqtlChart.value);
      }
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Tissue: ' +
              eqtlTissueList.value[params.value[1]] +
              '<br>Gene: ' +
              eqtlGeneList.value[params.value[0]] +
              '<br>-log10(P-value of SMR): ' +
              params.value[3].toFixed(4)
            );
          }
        },
        grid: {
          containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: eqtlGeneList.value,
          name:'Gene',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -90
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: eqtlTissueList.value,
          name:'Tissue',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'SMR_eQTL',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return [Chart.getWidth()/eqtlGeneList.value.length/2, Chart.getHeight()/eqtlTissueList.value.length/2]
            },
            data: eqtlFigData.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0,
          max: MaxEP.value,
          dimension: 3,
          right: 0,
          precision:0,
          top: 'center',
          text: ['', '-log10(P)'],
          calculable: true,
          range:[0, MaxEP.value],
          inRange: {
            color: ['#0055ff', '#ff0004']
          }
        },
      };
      Chart.setOption(options);
    }

    var studyOptions = ref([])
    var filterEqtlData = ref([])
    var eqtlTissue = ref('')
    var eqtlTissueOptions = ref([])
    var eqtlGene = ref('')
    var eqtlGeneOptions = ref([])
    var eqtlTopSNP = ref('')
    var eqtlTopSNPOptions = ref([])
    var MaxEP = ref(null)
    var MaxSP = ref(null)

    var filterEqtl = () => {
      if (eqtlData.value.length == 0){
        return
      }
      filterEqtlData.value = eqtlData.value.filter(
        (data) => {
          return (!eqtlTissue.value || data.tissue == eqtlTissue.value) &&
          (!eqtlGene.value || data.gene == eqtlGene.value) &&
          (!eqtlTopSNP.value || data.topSNP == eqtlTopSNP.value)
        }
      )
      eqtlTotal.value = filterEqtlData.value.length
      eqtlCurrentPage.value = 1
    }

    var resetEqtl = () => {
      eqtlTissue.value = ''
      eqtlGene.value = ''
      eqtlTopSNP.value = ''
      filterEqtlData.value = eqtlData.value
      eqtlTotal.value = filterEqtlData.value.length
      eqtlCurrentPage.value = 1
    }

    var eqtlTotal = ref(0)
    var eqtlCurrentPage = ref(1)
    var eqtlPageSize = ref(5)

    const eqtlPagedData = computed(() => {
      const startIndex = (eqtlCurrentPage.value - 1) * eqtlPageSize.value;
      const endIndex = startIndex + eqtlPageSize.value;
      return filterEqtlData.value.slice(startIndex, endIndex);
    });

    const sortEqtlChange = (arg) => {
      let sortData = filterEqtlData.value
      if (arg.order === 'descending') {
        if (arg.prop === 'gene' || arg.prop === 'topSNP' || arg.prop === 'a1' || arg.prop === 'a2'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'gene' || arg.prop === 'topSNP' || arg.prop === 'a1' || arg.prop === 'a2'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filterEqtlData.value = sortData
      eqtlCurrentPage.value = 1
    }

    const sortSqtlChange = (arg) => {
      let sortData = filterSqtlData.value
      if (arg.order === 'descending') {
        if (arg.prop === 'gene' || arg.prop === 'topSNP' || arg.prop === 'a1' || arg.prop === 'a2'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'gene' || arg.prop === 'topSNP' || arg.prop === 'a1' || arg.prop === 'a2'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filterSqtlData.value = sortData
      sqtlCurrentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }
    
    // 处理页码改变事件
    const handleEqtlCurrentChange = (newPage) => {
      eqtlCurrentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleEqtlSizeChange = (newSize) => {
      eqtlPageSize.value = newSize;
      eqtlCurrentPage.value = 1
    };

    const fetchTrait = async () => {
      try {
        const response = await proxy.$request.get('/getTrait',{
          params:{
            study: study.value
          }
        })
        trait.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetcheQTLData = async () => {
      Eloading.value = true
      try {
        const response = await proxy.$request.get('/getSMREQTL',{ 
          params:{
            study:study.value
        }})
        eqtlData.value = response.data
        if (eqtlData.value.length == 0){
          showEqtl.value = false
        }
      } catch (error) {
        console.log('fail', error.message)
      }
      Eloading.value = false
    }

    var sqtlData = ref([])
    var sqtlFigData = ref(null)

    var sqtlGeneList = ref([])
    // prettier-ignore
    var sqtlTissueList = ref([])

    var SMRsqtlChartInit = () => {
      let Chart = echarts.getInstanceByDom(SMRSqtlChart.value);
      if(Chart == null){
        Chart = echarts.init(SMRSqtlChart.value);
      }
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Tissue: ' +
              sqtlTissueList.value[params.value[1]] +
              '<br>Gene: ' +
              sqtlGeneList.value[params.value[0]] +
              '<br>-log10(P-value of SMR): ' +
              params.value[3].toFixed(4)
            );
          }
        },
        grid: {
          containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: sqtlGeneList.value,
          name:'Gene',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -90
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: sqtlTissueList.value,
          name:'Tissue',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'SMR_sQTL',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return [Chart.getWidth()/sqtlGeneList.value.length/2, Chart.getHeight()/sqtlTissueList.value.length/2]
            },
            data: sqtlFigData.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0,
          max: MaxSP.value,
          dimension: 3,
          right: 0,
          precision:0,
          top: 'center',
          text: ['', '-log10(P)'],
          calculable: true,
          range:[0, MaxSP.value],
          inRange: {
            color: ['#0055ff', '#ff0004']
          }
        },
      };
      Chart.setOption(options);
    }

    var filterSqtlData = ref([])
    var sqtlTissue = ref('')
    var sqtlTissueOptions = ref([])
    var sqtlGene = ref('')
    var sqtlGeneOptions = ref([])
    var sqtlTopSNP = ref('')
    var sqtlTopSNPOptions = ref([])

    var filterSqtl = () => {
      filterSqtlData.value = sqtlData.value.filter(
        (data) => {
          return (!sqtlTissue.value || data.tissue == sqtlTissue.value) &&
          (!sqtlGene.value || data.gene == sqtlGene.value) &&
          (!sqtlTopSNP.value || data.topSNP == sqtlTopSNP.value)
        }
      )
      sqtlTotal.value = filterSqtlData.value.length
      sqtlCurrentPage.value = 1
    }

    var resetSqtl = () => {
      sqtlTissue.value = ''
      sqtlGene.value = ''
      sqtlTopSNP.value = ''
      filterSqtlData.value = sqtlData.value
      sqtlTotal.value = filterSqtlData.value.length
      sqtlCurrentPage.value = 1
    }

    var sqtlTotal = ref(0)
    var sqtlCurrentPage = ref(1)
    var sqtlPageSize = ref(5)

    const sqtlPagedData = computed(() => {
      const startIndex = (sqtlCurrentPage.value - 1) * sqtlPageSize.value;
      const endIndex = startIndex + sqtlPageSize.value;
      return filterSqtlData.value.slice(startIndex, endIndex);
    });

    // 处理页码改变事件
    const handleSqtlCurrentChange = (newPage) => {
      sqtlCurrentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSqtlSizeChange = (newSize) => {
      sqtlPageSize.value = newSize;
      sqtlCurrentPage.value = 1;
    };

    const fetchsQTLData = async () => {
      Sloading.value = true
      try {
        const response = await proxy.$request.get('/getSMRSQTL',{ 
          params:{
            study:study.value
        }})
        sqtlData.value = response.data
        if (sqtlData.value.length == 0){
          showSqtl.value = false
        }
      } catch (error) {
        console.log('fail', error.message)
      }
      Sloading.value = false
    }

    watch(eqtlData,(newValue, oldValue)=>{
      if (eqtlData.value.length == 0){
        return 
      }
      let geneSet = new Set()
      let tissueSet = new Set()
      let topSNPSet = new Set()
      for (let i = 0; i < eqtlData.value.length; i++){
        geneSet.add(eqtlData.value[i].gene)
        tissueSet.add(eqtlData.value[i].tissue)
        topSNPSet.add(eqtlData.value[i].topSNP)
      }
      eqtlGeneList.value = [...geneSet]
      eqtlTissueList.value = [...tissueSet]
      let topSNPList = [...topSNPSet]
      eqtlGeneOptions.value = eqtlGeneList.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtlTissueOptions.value = eqtlTissueList.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtlTopSNPOptions.value = topSNPList.map((item) => {
        return {
          value:item,
          label:item
        }
      })

      var tmp = 0
      eqtlFigData.value = eqtlData.value.map((item) => {
        return [
          eqtlGeneList.value.indexOf(item.gene),
          eqtlTissueList.value.indexOf(item.tissue),
          item.psmr,
          -1.0*(Math.log10(item.psmr)),
          tmp = -1.0*(Math.log10(item.psmr)) > tmp ? -1.0*(Math.log10(item.psmr)) : tmp
        ]
      })
      MaxEP.value = tmp
      filterEqtl()
    })

    watch(filterEqtlData,(newValue, oldValue)=>{
      if (filterEqtlData.value.length != 0){
        let geneSet = new Set()
        let tissueSet = new Set()
        let topSNPSet = new Set()
        for (let i = 0; i < filterEqtlData.value.length; i++){
          geneSet.add(filterEqtlData.value[i].gene)
          tissueSet.add(filterEqtlData.value[i].tissue)
          topSNPSet.add(filterEqtlData.value[i].topSNP)
        }
        eqtlGeneList.value = [...geneSet]
        eqtlTissueList.value = [...tissueSet]

        var tmp = 0
        eqtlFigData.value = filterEqtlData.value.map((item) => {
          return [
            eqtlGeneList.value.indexOf(item.gene),
            eqtlTissueList.value.indexOf(item.tissue),
            item.psmr,
            -1.0*(Math.log10(item.psmr)),
            tmp = -1.0*(Math.log10(item.psmr)) > tmp ? -1.0*(Math.log10(item.psmr)) : tmp
          ]
        })
        MaxEP.value = tmp
        SMReqtlChartInit()
      }
    })
    
    watch(sqtlData,(newValue, oldValue)=>{
      if (sqtlData.value.length == 0){
        return
      }
      let geneSet = new Set()
      let tissueSet = new Set()
      let topSNPSet = new Set()
      for (let i = 0; i < sqtlData.value.length; i++){
        geneSet.add(sqtlData.value[i].gene)
        tissueSet.add(sqtlData.value[i].tissue)
        topSNPSet.add(sqtlData.value[i].topSNP)
      }
      sqtlGeneList.value = [...geneSet]
      sqtlTissueList.value = [...tissueSet]
      let topSNPList = [...topSNPSet]
      sqtlGeneOptions.value = sqtlGeneList.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      sqtlTissueOptions.value = sqtlTissueList.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      sqtlTopSNPOptions.value = topSNPList.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      filterSqtl()
    })

    watch(filterSqtlData,(newValue, oldValue)=>{
      if(filterSqtlData.value.length != 0){
        let geneSet = new Set()
        let tissueSet = new Set()
        let topSNPSet = new Set()
        for (let i = 0; i < filterSqtlData.value.length; i++){
          geneSet.add(filterSqtlData.value[i].gene)
          tissueSet.add(filterSqtlData.value[i].tissue)
          topSNPSet.add(filterSqtlData.value[i].topSNP)
        }
        sqtlGeneList.value = [...geneSet]
        sqtlTissueList.value = [...tissueSet]

        var tmp = 0
        sqtlFigData.value = filterSqtlData.value.map((item) => {
          return [
            sqtlGeneList.value.indexOf(item.gene),
            sqtlTissueList.value.indexOf(item.tissue),
            item.psmr,
            -1.0*(Math.log10(item.psmr)),
            tmp = -1.0*(Math.log10(item.psmr)) > tmp ? -1.0*(Math.log10(item.psmr)) : tmp
          ]
        })

        MaxSP.value = tmp
        SMRsqtlChartInit()
      }
    })

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: ';SMR'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    watch(study, (newValue, oldValue) => {
      showEqtl.value = true
      showSqtl.value = true
      fetchTrait()
      fetcheQTLData()
      fetchsQTLData()
    })

    onMounted(() => {
      fetchStudy()
      fetchTrait()
      fetcheQTLData()
      fetchsQTLData()
    })

    return {
      Eloading,
      Sloading,
      showEqtl,
      showSqtl,
      study,
      selectedStudy,
      trait,
      SMREqtlChart,
      SMRSqtlChart,
      eqtlPagedData,
      studyOptions,
      eqtlTissue,
      eqtlTissueOptions,
      eqtlGene,
      eqtlGeneOptions,
      eqtlTopSNP,
      eqtlTopSNPOptions,
      eqtlTotal,
      eqtlCurrentPage,
      eqtlPageSize,

      sqtlPagedData,
      sqtlTissue,
      sqtlTissueOptions,
      sqtlGene,
      sqtlGeneOptions,
      sqtlTopSNP,
      sqtlTopSNPOptions,
      sqtlTotal,
      sqtlCurrentPage,
      sqtlPageSize,

      filterEqtl,
      resetEqtl,
      handleEqtlCurrentChange,
      handleEqtlSizeChange,
      filterSqtl,
      resetSqtl,
      handleSqtlCurrentChange,
      handleSqtlSizeChange,
      sortEqtlChange,
      sortSqtlChange,
      searchStudy,
      fetchStudy
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .bar{
    width: 100%;
    height: 500px;
  }
</style>