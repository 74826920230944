<template>
  <el-tabs type="card">
    <el-tab-pane v-loading="loadingImg1" label="zoom 1x">
      <img :src="zoom1" alt="zoom 1x" style="width:40%" @load="loadingImg1 = false" @error="loadingImg1 = false">
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg2" label="zoom 4x">
      <img :src="zoom4" alt="zoom 4x" style="width:40%" @load="loadingImg2 = false" @error="loadingImg2 = false">      
    </el-tab-pane>
    <el-tab-pane v-loading="loadingImg3" label="zoom 10x">
      <img :src="zoom10" alt="zoom 10x" style="width:40%" @load="loadingImg3 = false" @error="loadingImg3 = false">    
    </el-tab-pane>
  </el-tabs>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">SNP:</div>
      <el-select-v2
        v-model="snp"
        placeholder="input and select a SNP"
        filterable
        :options="snpOptions"
        style="width: 240px"
      />
    </div>
    <div class="table_select">
      <div class="table_select">
        <div class="search_title">P {{ '<' }}</div>
        <el-input v-model="p" style="width: 240px" type="number" placeholder="input P" />
      </div>        
    </div>
    <div class="search_button">
      <el-button type="primary" @click="filterBySNPorP">Search</el-button>
      <el-button @click="reset">Reset</el-button>
    </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loadingTable"
        :data="tableData"
        :border="true"
        style="width: 100%"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column sortable prop="snp" label="SNP" min-width="75">
          <template v-slot="scope">
            <a v-if="scope.row.snp" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.snp" target="_blank">{{scope.row.snp}}</a>
          </template>
        </el-table-column> 
        <el-table-column sortable prop="chr" label="Chr" min-width="75"/>
        <el-table-column sortable prop="pos" label="Position" min-width="125"/>
        <el-table-column sortable prop="p" label="P" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="effect" label="Effect" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="std_err" label="SE" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="a1" label="A1" min-width="75"/>
        <el-table-column sortable prop="a2" label="A2" min-width="75"/>
        <el-table-column sortable prop="freq" label="Frequency" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="proportion_cases" label="Proportion Cases" min-width="125" :formatter="$utils.NAFormat"/> 
        <el-table-column sortable prop="n" label="N" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="tstat" label="T Statistics" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column prop="lead_snp" label="Lead SNP" min-width="125"/>
        <el-table-column sortable prop="maf" label="MAF" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="abf_cs" label="ABF CS" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="abf_pp" label="ABF PP" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="finemap_cs" label="FINEMAP CS" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="finemap_pp" label="FINEMAP PP" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="susie_cs" label="SUSIE CS" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="susie_pp" label="SUSIE PP" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="support" label="Support" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column prop="consensus_snp" label="Consensus SNP" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="mean_cs" label="mean CS" min-width="125" :formatter="$utils.NAFormat"/>
        <el-table-column sortable prop="mean_pp" label="mean PP" min-width="125" :formatter="$utils.NAFormat"/>
      </el-table>  
      
      <el-pagination
        style="float: right;margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="Total"
        :page-sizes="[5,10,20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" 
      /> 
    </div>
  </div>
</template>

<script>
import { ElTabs, ElTabPane, ElSelectV2, ElTable, ElTableColumn, ElButton, ElInput, ElDivider, ElPagination } from 'element-plus';
import { ref, computed, watch, onMounted, getCurrentInstance } from 'vue';

export default {
  name:'Finemap',
  components:{
    ElTabs, 
    ElTabPane, 
    ElSelectV2,
    ElTable, 
    ElTableColumn, 
    ElButton, 
    ElInput, 
    ElDivider, 
    ElPagination
  },
  props:{
    study: String,
    locus: String
  },
  setup(props){
    const { proxy } = getCurrentInstance()
    var loadingImg1 = ref(false)
    var loadingImg2 = ref(false)
    var loadingImg3 = ref(false)
    var loadingTable = ref(false)
    var zoom1 = ref('')
    var zoom4 = ref('')
    var zoom10 = ref('')

    var snp = ref('')
    var snpOptions = ref([])
    var p = ref(null)

    var tableData = ref([])

    var filterBySNPorP = () => {
      fetchData()
    }

    var reset = () => {
      snp.value = ''
      p.value = null
      currentPage.value = 1
      fetchData()
    }

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)
    var order = ''
    var prop = ''

    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchData()
    };
    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      fetchData()
    };

    const sortChange = (arg) => {
      order = arg.order
      prop = arg.prop
      fetchData()
    }

    const fetchSnp = async () => {
      loadingTable.value = true
      try {
        const response = await proxy.$request.get('/getFinemapSnp',{ 
          params:{
            study:props.study,
            gene:props.locus.split('_')[1]
        }})
        snpOptions.value = response.data.map((item) => {
          return {
            value:item,
            label:item
          }
        })
    }catch (error) {
        console.log('fail', error.message)
        loadingTable.value = false
      }
    }

    const fetchData = async () => {
      loadingTable.value = true
      try {
        const response = await proxy.$request.get('/pageFinemap',{ 
          params:{
            study:props.study,
            gene:props.locus.split('_')[1],
            snp: snp.value,
            p: p.value,
            page: currentPage.value,
            pageSize: pageSize.value,
            prop: prop,
            order: order
        }})
        tableData.value = response.data.rows
        Total.value = response.data.total
        loadingTable.value = false
      } catch (error) {
        console.log('fail', error.message)
        loadingTable.value = false
      }
    }

    const fetchPNG = () => {
      loadingImg1.value = true
      loadingImg2.value = true
      loadingImg3.value = true
      zoom1.value = 'http://43.154.207.136/png/finemap/'+props.study+'/'+props.locus.split('_')[1]+'_1x.png'
      zoom4.value = 'http://43.154.207.136/png/finemap/'+props.study+'/'+props.locus.split('_')[1]+'_4x.png'
      zoom10.value = 'http://43.154.207.136/png/finemap/'+props.study+'/'+props.locus.split('_')[1]+'_10x.png'
    }

    watch(() => props.locus, (newValue, oldValue) => {
      currentPage.value = 1
      snp.value = ''
      p.value = null
      fetchSnp()
      fetchData()
      fetchPNG()
    })

    watch(() => props.study, (newValue, oldValue) => {
      currentPage.value = 1
      snp.value = ''
      p.value = null
      fetchSnp()
      fetchData()
      fetchPNG()
    })

    onMounted(() => {
      fetchSnp()
      fetchData()
      fetchPNG()
    })

    return {
      loadingTable,
      loadingImg1,
      loadingImg2,
      loadingImg3,
      zoom1,
      zoom4,
      zoom10,
      snp,
      snpOptions,
      p,
      tableData,
      currentPage,
      pageSize,
      Total,

      filterBySNPorP,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>