<template>
  <div class="study_select">
    <div class="table_select">
      <div style="font-size: 16px; font-weight: bold">Study:</div>
      <el-select
        v-model="selectedStudy"
        placeholder="input and select a study"
        filterable
        style="width: 240px;margin-left: 10px">
        <el-option
          v-for="item in studyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="searchStudy">Search</el-button>
    </div>
  </div>
  <el-divider />
  <h2 style="text-align: center;font-size: 32px;">The COLOC results for {{ study }}</h2>
  <el-divider />
  <div class="coloceqtl">
    <h2 style="text-align: center;font-size: 32px;">Colocalized genes of {{ study }} by analysis on eQTL</h2>
    <div ref="colocEqtlChart" class="bar"></div>
    <div>
      <div class="table_search">
        <div class="table_select">
          <div class="search_title">Tissue:</div>
          <div>
            <el-select
              v-model="eqtlTissue"
              placeholder="input and select a tissue"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtlTissueOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">Gene:</div>
          <div>
            <el-select
              v-model="eqtlGene"
              placeholder="input and select a gene"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtlGeneOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">SNP:</div>
          <div>
            <el-select
              v-model="eqtlSNP"
              placeholder="input and select a top SNP"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtlSNPOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="search_button">
            <el-button type="primary" @click="filterEqtl">Search</el-button>
            <el-button @click="resetEqtl">Reset</el-button>
          </div>
      </div>
      <div class="table_container">
        <el-table 
          :data="pagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortChange"
        >
          <el-table-column prop="study" label="Study" min-width="150" />
          <el-table-column prop="Trait" label="Trait" min-width="150">
            <template v-slot:default>
            <div>
              {{ trait }}
            </div>
          </template>
        </el-table-column>
          <el-table-column prop="tissue" label="Tissue" min-width="150" />
          <el-table-column prop="gene" label="Gene" min-width="150">
            <template v-slot="scope">
              <a v-if="scope.row.Gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.Gene" target="_blank">{{scope.row.Gene}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="chr" label="Chr" min-width="150" />
          <el-table-column sortable prop="snps" label="#SNPs" min-width="150" /> 
          <el-table-column sortable prop="ppH4Abf" label="pp_h4_abf" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="pp4Pp3" label="pp4_pp3" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column prop="colocSnp" label="SNP" min-width="150" >
            <template v-slot="scope">
              <a v-if="scope.row.coloc_snp" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.coloc_snp" target="_blank">{{scope.row.coloc_snp}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="gwasP" label="GWAS_P" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="qtlP" label="eQTL_P" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="snpPpH4" label="snp_pp_h4" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ppH0Abf" label="pp_h0_abf" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ppH1Abf" label="pp_h1_abf" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ppH2Abf" label="pp_h2_abf" min-width="150" :formatter="$utils.NAFormat" /> 
          <el-table-column sortable prop="ppH3Abf" label="pp_h3_abf" min-width="150" :formatter="$utils.NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="eqtlCurrentPage"
          :total="eqtlTotal"
          :page-sizes="[5,10,20]"
          :page-size="eqtlPageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
      </div>
    </div>
  </div>
</template>

<script>
import { ElDivider, ElTable, ElTableColumn, ElSelect, ElOption, ElButton, ElPagination } from 'element-plus';
import * as echarts from 'echarts'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';

export default {
  name:'COLOC',
  components: {
    ElDivider,
    ElTable, 
    ElTableColumn,
    ElSelect,
    ElOption, 
    ElButton,
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const study = ref(route.params.study)
    const trait = ref('')
    const colocEqtlChart = ref(null)
    const selectedStudy = ref(route.params.study)
    var studyOptions = ref([])

    var eqtlData = ref(null)
    var eqtlFigData = ref(null)

    var eqtlGeneList = ref([])
    // prettier-ignore
    var eqtlTissueList = ref([])

    var colocEqtlChartInit = () => {
      let Chart = echarts.getInstanceByDom(colocEqtlChart.value);
      if(Chart == null){
        Chart = echarts.init(colocEqtlChart.value);
      }
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Tissue: ' +
              eqtlTissueList.value[params.value[1]] +
              '<br>Gene: ' +
              eqtlGeneList.value[params.value[0]] +
              '<br>pp_h4_abf: ' +
              params.value[2].toFixed(4)
            );
          }
        },
        grid: {
          containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: eqtlGeneList.value,
          name:'Gene',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -90
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: eqtlTissueList.value,
          name:'Tissue',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'SMR_eQTL',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return [Chart.getWidth()/eqtlGeneList.value.length/2, Chart.getHeight()/eqtlTissueList.value.length/2]
            },
            data: eqtlFigData.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0.75,
          max: 1,
          dimension: 2,
          right: 0,
          precision:2,
          top: 'center',
          text: ['', 'pp_h4_abf'],
          calculable: true,
          range:[0.75, 1],
          inRange: {
            color: ['#0055ff', '#ff0004']
          }
        },
      };
      Chart.setOption(options);
    }

    var eqtlGene = ref('')
    var eqtlGeneOptions = ref([])
    var eqtlTissue = ref('')
    var eqtlTissueOptions = ref([])
    var eqtlSNP = ref('')
    var eqtlSNPOptions = ref([])
    var filterEqtlData = ref([])

    var eqtlTotal = ref(0)
    var eqtlCurrentPage = ref(1)
    var eqtlPageSize = ref(5)

    const pagedData = computed(() => {
      const startIndex = (eqtlCurrentPage.value - 1) * eqtlPageSize.value;
      const endIndex = startIndex + eqtlPageSize.value;
      return filterEqtlData.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      eqtlCurrentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      eqtlPageSize.value = newSize;
      eqtlCurrentPage.value = 1
    };

    const sortChange = (arg) => {
      let sortData = filterEqtlData.value
      if (arg.order === 'descending') {
          sortData.sort(sortByProp(arg.prop, true))
      }else{
          sortData.sort(sortByProp(arg.prop, false))
      }
      filterEqtlData.value = sortData
      eqtlCurrentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    var filterEqtl = () => {
      filterEqtlData.value = eqtlData.value.filter(
        (data) => {
          return (!eqtlTissue.value || data.tissue==eqtlTissue.value) &&
          (!eqtlGene.value || data.gene == eqtlGene.value) &&
          (!eqtlSNP.value || data.colocSnp == eqtlSNP.value)
        }
      )
      eqtlTotal.value = filterEqtlData.value.length
      eqtlCurrentPage.value = 1
    }

    var resetEqtl = () => {
      eqtlTissue.value = ''
      eqtlGene.value = ''
      eqtlSNP.value = ''
      filterEqtlData.value = eqtlData.value
      eqtlTotal.value = filterEqtlData.value.length
      eqtlCurrentPage.value = 1
    }
    
    const fetcheQTLData = async () => {
      try {
        const response = await proxy.$request.get('/getColoc',{ 
          params:{
            study:study.value
        }})
        eqtlData.value = response.data
        console.log(response.data)
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const searchStudy = () => {
      study.value = selectedStudy.value
    }

    const fetchStudy = async () => {
      try {
        const response = await proxy.$request.get('/getStudy',{ 
          params:{
            method: 'COLOC'
        }})
        studyOptions.value = response.data.map((item) => {
          return {
            value:item.study_id,
            label:item.study_id+" ("+item.trait+")",
            title:item.study_id+" ("+item.trait+")"
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchTrait = async () => {
      try {
        const response = await proxy.$request.get('/getTrait',{
          params:{
            study: study.value
          }
        })
        trait.value = response.data
      } catch(error) {
        console.log('fail', error.message)
      }
    }

    watch(eqtlData,(newValue, oldValue)=>{
      let geneSet = new Set()
      let tissueSet = new Set()
      let SNPSet = new Set()
      for (let i = 0; i < eqtlData.value.length; i++){
        geneSet.add(eqtlData.value[i].gene)
        tissueSet.add(eqtlData.value[i].tissue)
        SNPSet.add(eqtlData.value[i].colocSnp)
      }
      eqtlGeneList.value = [...geneSet]
      eqtlTissueList.value = [...tissueSet]
      let SNP_list = [...SNPSet]
      eqtlGeneOptions.value = eqtlGeneList.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtlTissueOptions.value = eqtlTissueList.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtlSNPOptions.value = SNP_list.map((item) => {
        return {
          value:item,
          label:item
        }
      })

      eqtlFigData.value = eqtlData.value.map((item) => {
        return [
          eqtlGeneList.value.indexOf(item.gene),
          eqtlTissueList.value.indexOf(item.tissue),
          item.ppH4Abf,
          item.pp4Pp3
        ]
      })
      filterEqtl()
      colocEqtlChartInit()
    })

    watch(filterEqtlData,(newValue, oldValue)=>{
      if (filterEqtlData.value.length != 0){
        let geneSet = new Set()
        let tissueSet = new Set()
        let topSNPSet = new Set()
        for (let i = 0; i < filterEqtlData.value.length; i++){
          geneSet.add(filterEqtlData.value[i].gene)
          tissueSet.add(filterEqtlData.value[i].tissue)
          topSNPSet.add(filterEqtlData.value[i].topSNP)
        }
        eqtlGeneList.value = [...geneSet]
        eqtlTissueList.value = [...tissueSet]

        eqtlFigData.value = filterEqtlData.value.map((item) => {
          return [
            eqtlGeneList.value.indexOf(item.gene),
            eqtlTissueList.value.indexOf(item.tissue),
            item.ppH4Abf,
          item.pp4Pp3
          ]
        })
        colocEqtlChartInit()
      }
    })
    
    watch(study, (newValue, oldValue) => {
      fetchTrait()
      fetcheQTLData()
    })

    onMounted(() => {
      fetchStudy()
      fetchTrait()
      fetcheQTLData()
    })

    return {
      study,
      trait,
      colocEqtlChart,
      eqtlGene,
      eqtlGeneOptions,
      eqtlTissue,
      eqtlTissueOptions,
      eqtlSNP,
      eqtlSNPOptions,
      eqtlTotal,
      eqtlCurrentPage,
      eqtlPageSize,
      pagedData,
      selectedStudy,
      studyOptions,

      handleCurrentChange,
      handleSizeChange,
      filterEqtl,
      resetEqtl,
      sortChange,
      searchStudy,
      fetchStudy
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>