<template>
  <div class="container">
    <h2 class="title">
      <span style="color:#a82d26">C</span>ardio<span style="color:#335495">G</span>ene: a comprehensive platform for cardiovascular diseases and related phenotypes
    </h2>
    <el-divider />
    <div class="search">
      <el-select
        v-model="selected_type"
        size="large"
        style="width: 100px">
        <el-option
          v-for="item in select_type"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div class="search_box">
        <el-input
          v-if="selected_type == 'Gene'"
          v-model="Trait"
          style="width: 100%"
          placeholder="Please input a gene (e.g. APOE)"
          @keyup.enter = "search"
          clearable
          size="large"
        />
        <el-input
          v-else-if="selected_type == 'SNP'"
          v-model="Trait"
          style="width: 100%"
          placeholder="Please input a SNP (e.g. rs3918286)"
          @keyup.enter = "search"
          clearable
          size="large"
        />
        <el-autocomplete
          v-if="selected_type == 'Trait'"
          type="text"
          placeholder="Please input a trait (e.g. Coronary Artery Disease)"
          :fetch-suggestions="querySearch"
          clearable
          v-model="Trait"
          size="large"
          @keyup.enter="search"
        />
      </div>
      <button class="search_btn" @click="search">
        <SearchOutlined class="search_icon"/>
      </button>
    </div>
    <!-- <div style="display: flex;justify-content: center;">
      <div style="width: 52%;">
        <div class="radio_select">
          <div>Search by:</div>
          <el-radio-group style="margin-left: 20px;" v-model="searchKey">
            <el-radio :value="'Gene'">Gene</el-radio>
            <el-radio :value="'SNP'">SNP</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div> -->
    <!-- <el-divider /> -->
    <div class="statistics">
      <div class="stat_item" @click="routePush('FineMapping', 'AT099')">
        <div class="class_name">SNP Level</div>
          <img class="class_img" src="../assets/snp_level.png">
      </div>
      <div class="stat_item" @click="routePush('Heritability', 'AT099')">
        <div class="class_name">Genetic Overlap</div>
        <img class="class_img" src="../assets/heritability.png">
      </div>
      <div class="stat_item" @click="routePush('GARFIELD', 'AT099')">
        <div class="class_name">Fuctional Annotations</div>
        <img class="class_img" src="../assets/function_annotation.png">
      </div>
      <div class="stat_item" @click="routePush('SMR', 'AT099')">
        <div class="class_name">Gene Level</div>
        <img class="class_img" src="../assets/gene_level.png">
      </div>
      <div class="stat_item" @click="routePush('TSMR', 'AT011')">
        <div class="class_name">Causal Inference</div>
        <img class="class_img" src="../assets/casual_inference.png">
      </div>
    </div>
    <div class="box">
      <div class="left_container">
        <div class="box_border">
          <div class="intro">
            About CardioGene Database
          </div>
          <div class="about">
            <div>CardioGene is a platform dedicated to understanding the genetic basis of cardiovascular related traits. The platform integrates 1518 GWAS summary statistics, including diseases, biochemical indicators, physiological measurements, drugs. The analysis results can be viewed by clicking the <span style="color: #0c64b6;font-weight: bold;">method label</span> on the 
              <router-link style="color: #0c64b6;font-weight: bold;text-decoration: none;"
                :to="{name:'Overview'}"
              >
                Overview
              </router-link>
               page. Through its extensive database and thorough analysis tools, CardioGene aims to provide deeper insights into the genetic factors underlying cardiovascular related traits.</div>
            <div>CardioGene addresses these challenges by offering comprehensive analysis through 13 methods to identify potential causal genes, variants, and relevant tissues or cell types for cardiovascular related traits, dividing into the following five aspects:</div>
            <ul>
              <!-- <li>Cardiovascular diseases are a major cause of death globally, estimated to claim 17.9 million lives each year, significantly impacting human health, quality of life, and healthcare costs. Understanding the complex genetic mechanisms of cardiovascular diseases is crucial for developing targeted therapies, improving prevention strategies, and ultimately reducing the global burden of these life-threatening conditions.</li> -->
              <li><span style="font-weight: bold;">SNP Level:</span> Causal variants identification by three Finemapping methods <a class="method" href="https://chr1swallace.github.io/coloc/reference/finemap.abf.html" target="_blank">ABF</a>, <a class="method" href="http://www.christianbenner.com/" target="_blank">FINEMAP</a>, and <a class="method" href="https://stephenslab.github.io/susieR/" target="_blank">SuSiE</a>;</li>
              <li><span style="font-weight: bold;">Genetic Overlap:</span> Heritability, heritability enrichment and heritability correlation analysis by two software <a class="method" href="https://github.com/bulik/ldsc" target="_blank">LDSC</a> and <a class="method" href="https://dougspeed.com/ldak/" target="_blank">LDAK</a>;</li>
              <li><span style="font-weight: bold;">Fuctional Annotations:</span> Fuctional Annotations by <a class="method" href="https://www.ebi.ac.uk/birney-srv/GARFIELD/" target="_blank">GARFIELD</a> and <a class="method" href="https://ctg.cncr.nl/software/magma" target="_blank">MAGMA</a>;</li>
              <li><span style="font-weight: bold;">Gene Level:</span> Causal genes identification by TWAS (<a class="method" href="https://github.com/hakyimlab/MetaXcan" target="_blank">S-PrediXcan</a>, <a class="method" href="https://github.com/Joker-Jerome/UTMOST" target="_blank">UTMOST</a>, <a class="method" href="https://github.com/gamazonlab/MR-JTI" target="_blank">JTI</a>), <a class="method" href="https://yanglab.westlake.edu.cn/software/smr/#Overview" target="_blank">SMR</a> and <a class="method" href="http://chr1swallace.github.io/coloc/index.html" target="_blank">COLOC</a>;</li>
              <li><span style="font-weight: bold;">Causal Inference:</span> Causal inference between exposure and outcome by <a class="method" href="https://mrcieu.github.io/TwoSampleMR/" target="_blank">TwoSampleMR</a>;</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right_container">
        <div class="top_container">
          <div class="intro">
            Statistics
          </div>
          <div class="statistics1">
            <div class="stat1">
              <img class="sta_icon" src="../assets/snp.png">
              <div>
                <el-statistic
                value-style="color:black"
                :value="statistic_snp"
                />
                Trait Variants
              </div>
            </div>
            <div class="stat1">
              <img class="sta_icon" src="../assets/gene.png">
              <div>
                <el-statistic
                value-style="color:black"
                :value="statistic_gene"
                />
                Genes
              </div>
            </div>
          </div>
          <div class="statistics1">
            <div class="stat1">
              <img class="sta_icon" src="../assets/trait.png">
              <div>
                <el-statistic
                value-style="color:black"
                :value="statistic_trait"
                />
                Traits
              </div>
            </div>
            <div class="stat1">
              <img class="sta_icon" src="../assets/cell.png">
              <div>
                <el-statistic
                value-style="color:black"
                :value="statistic_cell"
                />
                Cell Types
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_container">
          <div class="intro">
            Release Note
          </div>
          <div class="release">
            <el-timeline style="margin-top: 30px;">
              <el-timeline-item style="font-size: 20px"
                v-for="(activity, index) in activities"
                :key="index"
                type="primary"
                :hollow="true"
                :timestamp="activity.timestamp"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="intro">
      Components of CardioGene
    </div>
    <div class="about">
      <ul>
        <li>
          MAGMA is used for gene analysis and generalized gene-set analysis of GWAS summary statistics.
        </li>
        <li>
          LDAK and LDSC compute SNP-based heritability and genetic correlations between phenotypes, respectively. LDAK additionally estimates unique and total heritability across 66 components.
        </li>
        <li>
          Gene-based TWAS association by S-PrediXcan, UTMOST and JTI.
        </li>
        <li>
          GARFIELD integrates GWAS summary statistics with regulatory or functional annotations.
        </li>
        <li>
          Finemapping analysis by ABF, FINEMAP and SuSiE.
        </li>
        <li>
          Analysis between two Kinds of xQTLs and cardiovascular diseases and related phenotypes by SMR and COLOC.
        </li>
      </ul>
    </div> -->
    <div style="margin-bottom: 18px;">
      <div class="box_border">
        <div class="intro">
          How to Cite
        </div>
        <div class="about" style="padding-bottom: 10px;">
          CardioGene: a comprehensive platform for cardiovascular diseases and related phenotypes
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, getCurrentInstance } from 'vue'
import { ElInput, ElSelect, ElOption, ElDivider, ElAutocomplete, ElRadioGroup, ElRadio, ElStatistic, ElTimeline, ElTimelineItem, ElMessage } from 'element-plus';
import {CloseCircleOutlined, SearchOutlined} from '@ant-design/icons-vue'
import { useTransition } from '@vueuse/core'
import { useRouter } from 'vue-router';

export default {
  name:'Home',
  components:{
    CloseCircleOutlined,
    SearchOutlined,
    ElDivider,
    ElRadioGroup,
    ElRadio,
    ElAutocomplete,
    ElStatistic,
    ElTimeline,
    ElTimelineItem,
    ElSelect,
    ElOption,
    ElInput,
    ElMessage
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    var Trait = ref('')
    var traitArray = ref([])

    const snp = ref(0)
    const statistic_snp = useTransition(snp, {
      duration: 1500,
    })
    snp.value = 89497482

    const gene = ref(0)
    const statistic_gene = useTransition(gene, {
      duration: 1500,
    })
    gene.value = 30591

    const trait = ref(0)
    const statistic_trait = useTransition(trait, {
      duration: 1500,
    })
    trait.value = 193

    const cell = ref(0)
    const statistic_cell = useTransition(cell, {
      duration: 1500,
    })
    cell.value = 190

    var selected_type = ref('Trait')

    const select_type = [
      {
        value:'Trait',
        label:'Trait'
      },
      {
        value:'SNP',
        label:'SNP'
      },
      {
        value:'Gene',
        label:'Gene'
      }
    ]

    const activities = [
      {
        content:'CardioGene version 1.0 is released.',
        timestamp:'2024-10-25'
      },
      {
        content:'Add TwoSampleMR.',
        timestamp:'2024-10-18'
      },
      {
        content:'Bug fixed.',
        timestamp:'2024-08-06'
      },
      {
        content:'Database online.',
        timestamp:'2024-06-30'
      }
    ]

    function cleanSearch(){
      Trait.value = ''
    }
    const search = () => {
      Trait.value = Trait.value.trim()
      if (Trait.value == ''){
        ElMessage({
          message: 'searchWord can not be null!',
          type: 'warning'
        })
        return
      }
      if(selected_type.value == 'Trait'){
        router.push({
          path:'/Overview',
          query: {
            Trait: Trait.value,
          }
        })
      }else if(selected_type.value == 'Gene'){
        router.push({
          path:'/Search',
          query: {
            Gene: Trait.value
          }
        })
      }else if(selected_type.value == 'SNP'){
        router.push({
          path:'/Search',
          query: {
            Variant: Trait.value
          }
        })
      }
    }
    const routePush = (name, param) => {
      router.push({
        name: name,
        params:{
          study: param
        }
      })
    }

    const querySearch = (queryString, cb) => {
      const results = queryString
      ? traitArray.value.filter(createFilter(queryString))
      : traitArray.value
      cb(results)
    }

    const createFilter = (queryString) => {
      return (traitArray) => {
        return (
          traitArray.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    }

    const fetchetaritsData = async () => {
      try {
        const response = await proxy.$request.get('/meshTerm')
        traitArray.value = response.data.map((item) => {
          return {
            value:item,
            link:item
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    onMounted(()=>{
      fetchetaritsData()
    })

    return {
      Trait,
      statistic_snp,
      statistic_gene,
      statistic_trait,
      statistic_cell,
      activities,
      select_type,
      selected_type,

      cleanSearch,
      search,
      routePush,
      querySearch
    }
  }
}
</script>

<style scoped>
  .container{
    padding: 0,100px,0,100px;
  }
  .title{
    text-align: center;
    font-family: "Trebuchet MS", Arial, sans-serif;
    font-weight: 600;
    font-size: 30px;
    background-color:rgba(240, 242, 245, 1);
    min-height: 70px;
    line-height: 70px;
    margin: 0;
  }
  .sep{
    border-top: 1px solid rgba(0, 0, 0, .06);
    margin: 24px 0;
  }
  .search{
    display: flex;
    justify-content: center;
    text-align: center;
    align-content: center;
  }
  .search_box{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 50%;
  }
  .radio_select{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .intro{
    text-align: center;
    font-size: 26px;
    font-family: "Trebuchet MS", Arial, sans-serif;
    font-weight: bold;
    margin: 20px 0;
  }
  .about{
    font-size: 20px;
    line-height: 2.0;
    text-align: justify;
  }
  input{
    width:100%;
    height: 25px;
    font-size: 16px;
    border: none;
    outline:none
  }
  input:focus{
    outline:none
  }
  input::placeholder{
    color: #d0d0d0;
  }
  .clean_icon{
    color:#b2b2b2
  }
  .clean_icon:hover{
    color:#606060
  }
  .search_icon{
    color: white;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
  .search_btn{
    border: 1px solid #0c64b6;
    border-radius: 0 2px 2px 0;
    background-color: #0c64b6;
    transition: border 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
    width: 50px;
  }
  .search_btn:hover{
    border: 1px solid #127fe5;
    background-color: #127fe5;
    cursor: pointer;
  }
  .statistics{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin: 0 400px;
  }
  .statistics1{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
  }
  .stat_item{
    /* flex-grow: 1; */
    width: 300px;
    height: 100%;
    margin: 60px 10px 60px 10px;
    border-radius: 10px;
    border: solid 1px rgb(186, 186, 186);
    transition: border 0.3s ease-in-out;
  }
  .stat_item:hover{
    transition: border 0.3s ease-in-out;
    border: 1px solid #5f5f5f;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor:pointer
  }
  .stat_num{
    font-size: 42px;
    font-weight: bolder;
    color: #0c64b6;
  }
  .class_name{
    font-size: 25px;
    font-weight: bold;
    color: rgb(111, 108, 127);
    width: 300px;
  }
  .class_img{
    margin-top: 20px;
    width: 250px;
  }
  .stat1{
    flex: 1;
  }
  .sta_icon{
    width: 40px
  }
  .box{
    display: flex;
    align-items: stretch;
    margin-bottom: 18px;
  }
  .left_container{
    width: 60%;
    /* flex: 1; */
    margin-right: 8px;
  }
  .right_container{
    width: 40%;
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
  .box_border, .top_container, .bottom_container{
    border: 5px solid rgb(226, 226, 226);
    padding: 0 30px 0 20px;
  }
  .top_container, .bottom_container{
    flex: 1;
    height: 50%;
    /* min-height: 0px */
  }
  .top_container{
    margin-bottom: 6px;
    position: relative;
  }
  .bottom_container{
    margin-top: 6px;
  }
  .method{
    text-decoration: none;
    color: #0c64b6;
  }
  :deep(.el-input__wrapper){
    border-radius: 0;
  }
  :deep(.el-select__wrapper){
    border-radius: 5px 0 0 5px;
  }
</style>