<template>
  <div>
    <div class="table_search">
      <div class="table_select">
        <div class="search_title">MeSH Term:</div>
        <el-select
          v-model="trait"
          placeholder="select a MeSH Term"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in traitOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="table_select">
        <div class="search_title">Trait Type:</div>
        <el-select
          v-model="traitType"
          placeholder="select a trait type"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="table_select">
        <div class="search_title">Population:</div>
        <el-select
          v-model="population"
          placeholder="select a population"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in populationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="search_button">
        <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
        <el-button @click="reset">Reset</el-button>
      </div>
    </div>
    <div>
      <div class="table_container">
        <el-table 
          v-loading="loading"
          :data="tableData"
          :border="true"
          style="width: 100%"
          @sort-change="sortChange"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
        >
          <el-table-column sortable prop="study_id" label="Study" />
          <el-table-column prop="trait" label="Trait" width="300" />
          <el-table-column prop="MeSH_term" label="MeSH Term" :formatter="NAFormat" />
          <el-table-column prop="trait_type" label="Trait Type" :formatter="traitTypeFormat" />                                                                                                                                                                                                                                                                                                                                                                              " />
          <el-table-column sortable prop="n_control" label="NControl" :formatter="NAFormat" />
          <el-table-column sortable prop="n_case" label="NCase" :formatter="NAFormat" />
          <el-table-column sortable prop="sample_size" label="Sample Size" :formatter="NAFormat" />
          <el-table-column prop="population" label="Population" />
          <el-table-column prop="author" label="Author" />
          <el-table-column sortable prop="year" label="Year" /> 
          <el-table-column sortable prop="PMID" label="PMID" :formatter="NAFormat" >
            <template v-slot="scope">
              <a v-if="scope.row.PMID !== ''" class="pmid" :href="'https://pubmed.ncbi.nlm.nih.gov/'+scope.row.PMID" target="_blank">{{scope.row.PMID}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="method" label="Method" width="300px">
            <template #default="{ row }">
              <router-link
                class="table_method"
                v-for="method in row.method.split(';')"
                :key="method"
                :to="{
                  name:changeMethod(method),
                  params:{
                    study:row.study_id
                  }
                }"
                target="_blank"
                >
                {{ method }}
              </router-link>
            </template>
          </el-table-column>
        </el-table>  
        
        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="Total"
          :page-sizes="[5,10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watch, getCurrentInstance  } from 'vue'
import { ElTable, ElTableColumn, ElSelect, ElOption, ElButton, ElPagination } from 'element-plus'
import { useRoute } from 'vue-router';
export default {
  name:'Overview',
  components:{
    ElTable,
    ElTableColumn,
    ElOption,
    ElSelect,
    ElButton,
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    var loading = ref(false)

    var tableData = ref([])
    var filterTableData = ref([])
    
    var trait = ref('')
    var traitOptions = ref([])
    var traitType = ref(null)
    var typeOptions = reactive([
      {
        value:'0',
        label:'binary'
      },
      {
        value:'1',
        label:'quantitative'
      }
    ])
    var population = ref('')
    var populationOptions = reactive([
      {
        value:'AFR',
        label:'AFR'
      },
      {
        value:'AMR',
        label:'AMR'
      },
      {
        value:'EAS',
        label:'EAS'
      },
      {
        value:'EUR',
        label:'EUR'
      },
      
      {
        value:'MIX',
        label:'MIX'
      },
      {
        value:'SAS',
        label:'SAS'
      },
    ])
    var currentPage = ref(1)
    var pageSize = ref(10)
    var Total = ref(0)
    var prop = ''
    var order = ''

    const sortChange = (arg) => {
      prop = arg.prop
      order = arg.order
    
      fetchTableData()
    }

    const fetchTableData = async () => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/pageStudy',{ 
          params:{
            page: currentPage.value,
            pageSize: pageSize.value,
            trait: trait.value,
            traitType: traitType.value,
            population: population.value,
            prop: prop,
            order: order
        }});
        tableData.value = response.data.rows
        Total.value = response.data.total
      } catch (error) {
        console.log('fail', error.message)
      }
      loading.value = false
    }

    const fetchSelectionData = async() => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/meshTerm');
        traitOptions.value = response.data.map(item => ({
          label: item,
          value: item
        }))
      } catch (error){
        console.log('fail', error.message)
      }
      loading.value = false
    }

    const filterByTraitOrAuthor = () => {
      fetchTableData()
    }

    var reset = () => {
      trait.value = ''
      traitType.value = ''
      population.value = ''
      currentPage.value = 1
      fetchTableData()
      // Total.value = filterTableData.value.length
    }
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
      fetchTableData();
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      currentPage.value = 1
      fetchTableData();
    };

    const NAFormat = (row,column,value) => {
      return value === null ? '-' : value === "" ? '-' : value
    }

    const traitTypeFormat = (row,column,value) => {
      return value == 0 ? 'binary' : 'quantitative'
    }

    const changeMethod = (method) => {
      if (method === "LDAK" || method === "LDSC"){
        return 'Heritability'
      }
      return method
    }

    onMounted(() => {
      if(route.query.Trait){
        trait.value = route.query.Trait
      }
      fetchTableData()
      fetchSelectionData()
    })

    return {
      loading,
      filterTableData,
      trait,
      traitOptions,
      traitType,
      typeOptions,
      population,
      populationOptions,
      reset,
      currentPage,
      pageSize,
      Total,
      tableData,
      handleCurrentChange,
      handleSizeChange,
      NAFormat,
      traitTypeFormat,
      changeMethod,
      sortChange,
      filterByTraitOrAuthor
    }
  }
}
</script>

<style scoped>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .table_container{
    padding: 0 24px 24px 24px;
    overflow-x: auto;
  }
  .table_search{
    display: flex;
    width: 100%;
    margin-bottom: 30px;
  }
  .search_title{
    margin-right: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .search_button{
    flex: right;
  }
  .table_select{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .table_method{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    margin: .5ch;
    border: 1px solid rgb(189, 189, 189);
    padding: 0 10px;
    border-radius: 16px;
    height: 24px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color, 0.3s ease-in-out;
  }
  .table_method:hover{
    background-color: rgb(235, 235, 235);
  }
</style>